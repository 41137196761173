<script>
import financingService from '@/services/Financing/financingService';
import WizardSteps from '@/components/WizardSteps';

import InvestmentInformations from '@/views/InvestmentInformations';
// import InvestorInformations from '@/views/InvestorInformations';
import TermsOfUse from '@/views/TermsOfUse';
import PaymentInvestment from '@/views/PaymentInvestment';
import FinishScreen from '@/views/FinishScreen';

import settingsService from '@/services/Settings/settingsService';

import pessoasService from '@/services/Pessoas/pessoasService';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

import hubspotService from '@/services/HubSpot/hubspotService';

export default {
  components: {
    WizardSteps,
    InvestmentInformations,
    // InvestorInformations,
    TermsOfUse,
    PaymentInvestment,
    FinishScreen
  },
  data() {
    return {
      financialDetails: {
        cidade: {},
        estado: {},
        categoria: {},
        totais: {},
        users: {
          created_by: {},
        },
        endereco: {},
        fields: {}
      },
      isLoading: true,
      currentStep: 0,
      steps: [
        { id: 1, name: 'Contribuição' },
        // { id: 2, name: 'Meus Dados' },
        { id: 2, name: 'Termos' },
        { id: 3, name: 'Pagamento' },
        { id: 4, name: 'Finalização' }
      ],
      settings: {},
      pessoa: this.$CWLPessoaPadrao,
    };
  },
  computed: {
    financingId() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.getters.getPessoa;
    },
  },
  mounted() {
    this.getFinancialDetail();
    this.getSettings();
    this.getPessoa();
  },
  methods: {
    async atualizarCarrinhoAbandonado() {
      try {
        hubspotService.atualizarContatoAPI(this.pessoa.id, this.financialDetails.name);
      }
      catch (error) {
        console.log(error);
      }
    },
    async calculosDaCampanha(financ) {
      return this.$CWLCampanhaCalculos(financ);
    },
    async getFinancialDetail() {
      this.financialDetails = await financingService.findById(this.financingId);

      await this.calculosDaCampanha(this.financialDetails);

      this.isLoading = false;
    },
    async getSettings() {
      this.settings = await settingsService.getPublicSettings();
    },
    async getPessoa() {
      const { pessoa_id } = this.user;

      const pessoa = await pessoasService.getPessoa(pessoa_id);

      this.pessoa = pessoa.data();

      if (!this.pessoa.dados_complementares.dados_bancarios) {
        this.pessoa.dados_complementares.dados_bancarios = {};
      }

      if (!this.pessoa.dados_complementares.perfil) {
        this.pessoa.dados_complementares.perfil = {};
      }

      this.pessoa.id = pessoa.id;

      this.atualizarCarrinhoAbandonado();

      this.nextStep();
    },
    nextStep() {
      this.$store.commit('SET_USER', this.pessoa);

      if (
        this.currentStep == 1 &&
        !this.settings.needRegistrationConfirmation
      ) {
        this.currentStep = 3;
      } else {
        this.currentStep++;
      }
    },
    prevStep() {
      this.currentStep--;
    },
    updateInformations() {
      this.getFinancialDetail();
    }
  }
};
</script>

<template>
  <div class="new-investment">
    <div class="checkout-header">
      <wizard-steps :steps="steps" :currentStep="currentStep" />
    </div>

    <investment-informations
      v-if="currentStep == 1"
      :financing="financialDetails"
      @nextStep="nextStep()"
      @updateInformations="updateInformations()"
    />

    <!-- <investor-informations v-if="currentStep == 2" @nextStep="nextStep()" /> -->
    <terms-of-use v-if="currentStep == 2" @nextStep="nextStep()" :financing="financialDetails"></terms-of-use>
    <payment-investment v-if="currentStep == 3" @nextStep="nextStep()" />
    <finish-screen v-if="currentStep == 4"></finish-screen>
  </div>
</template>
