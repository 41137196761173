import firebase from 'firebase';

export default {
  collection() {
    return firebase.firestore().collection('settings');
  },

  async getPublicSettings() {
    try {
      const snapshot = await this.collection()
        .doc('public')
        .get();

      return snapshot.data() || {};
    } catch (e) {
      console.error('get settings error', e);
      return {};
    }
  },

  async getPrivateSettings() {
    try {
      const snapshot = await this.collection().doc('private').get();
      return snapshot.data() || {};
    } catch (e) {
      console.error('get settings error', e);
      return {};
    }
  },

  async getIntegracaoSafe2PaySettings() {
    let privateSettings = await this.getPrivateSettings();
    if ( privateSettings.integracao && privateSettings.integracao.safe2pay ) {
      return privateSettings.integracao.safe2pay;
    }
    else {
      return {};
    }
  },

  async getFeaturesToggle() {
    try {
      const snapshot = await this.collection().doc('private').get();
      const docData = snapshot.data();
      if ( docData && docData._features ) {
        return docData._features;
      }
      else {
        return {};
      }
    } catch (e) {
      console.error('get settings error', e);
      return {};
    }
  },
};
