import { Storage } from '@/firebase/index';
import firebase from 'firebase';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },

  async getTermsAttachments(storage = 'InvestmentTerms') {
    const storageEnvironment = Storage.ref(storage);

    const response = await storageEnvironment.listAll();

    return response;
  },

  async urlAttachment(file, storage = 'InvestmentTerms') {
    var urlAtt = '';
    const storageEnvironment = Storage.ref(storage);
    await storageEnvironment
      .child(file)
      .getDownloadURL()
      .then(function(url) {
        urlAtt = url;
      });

    return urlAtt;
  },

  async addNewAttachment(attachment, fileName, storage = 'Standard') {
    const storageRef = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');
    const response = await storageRef
      .child(fileNameAdjusted)
      .putString(attachment, "data_url");

    return response;
  },

};
