<script>
import { DoubleBounce } from 'vue-loading-spinner';
import VueHtml2pdf from 'vue-html2pdf';
import settingsService from '@/services/Settings/settingsService';

export default {
  name: 'contrato-modelo',
  props: {
    error: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      required: false,
      default: 'CÉDULA DE CRÉDITO BANCÁRIO',
    },
    objeto: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      pdfContract: "",
      downloadPDF: false,
      isBancarizadorManual: false,
      isBancarizadorEagle: false,
    };
  },
  computed: {},
  components: {
    DoubleBounce,
    VueHtml2pdf
  },
  methods: {
    downloadContratoCCB(downloadPDF = true) {
      this.isLoading = true;
      this.downloadPDF = downloadPDF;
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      const privateSettings = await settingsService.getPrivateSettings();

      let that = this;

      var optionsPdf = options;
      optionsPdf.enableLinks = true;
      optionsPdf.margin = [ 1.1, 0, 1, 0 ];
      optionsPdf.pageBreak= {mode: ['avoid-all', 'css']};
      optionsPdf.html2canvas = {
        scale: 1
      };
      
      var img = new Image();
      img.src = require('@/assets/images/fiducia_logo.png');
      if ( privateSettings.is_bancarizador_eagle ) {
        img.src = require('@/assets/images/ajx_logo.png');
      }

      var imgViaNegociavel = new Image();
      imgViaNegociavel.src = require('@/assets/images/minuta.png');

      if ( that.downloadPDF ) {
        await html2pdf().set(optionsPdf).from(pdfContent).toPdf().get('pdf').then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);

            let coordX = (pdf.internal.pageSize.getWidth() * 0.88);
            let coordY = (pdf.internal.pageSize.getHeight() - 0.3);
            pdf.text('Pág.: ' + i + ' de ' + totalPages, coordX, coordY);
            if ( !privateSettings.is_bancarizador_manual || privateSettings.is_bancarizador_eagle ) {
              pdf.addImage(img, 'PNG', 0.45, 0.6, 1.4, 0.5);
            }

            pdf.addImage(imgViaNegociavel, 'PNG', 1.5, 2, 9, 12);
          }
        }).save();
      }
      else {
        await html2pdf().set(optionsPdf).from(pdfContent).toPdf().get('pdf').then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);

            let coordX = (pdf.internal.pageSize.getWidth() * 0.88);
            let coordY = (pdf.internal.pageSize.getHeight() - 0.3);
            pdf.text('Pág.: ' + i + ' de ' + totalPages, coordX, coordY);
            if ( !privateSettings.is_bancarizador_manual || privateSettings.is_bancarizador_eagle ) {
              try {
                pdf.addImage(img, 'PNG', 0.45, 0.6, 1.4, 0.5);
              }
              catch (error) {
                pdf.addImage(img, 'PNG', 0.45, 0.6, 1.4, 0.5);
              }
            }
          }
        }).output('datauristring').then(function (pdfAsString) {
          that.$emit('pdfContratoCCBGenerated', pdfAsString);
        });
      }
      this.isLoading = false;
    }
  },
  async mounted() {
    let that = this;
    let privateSettings = await settingsService.getPrivateSettings();
    this.isBancarizadorManual = privateSettings.is_bancarizador_manual != undefined ? privateSettings.is_bancarizador_manual : false;
    this.isBancarizadorEagle = privateSettings.is_bancarizador_eagle != undefined ? privateSettings.is_bancarizador_eagle : false;
    setTimeout( function () {
      that.downloadContratoCCB(false);
    }, 1000);
  }
};
</script>

<template>
  <div>
    <div class="row download-contrato">
      <div class="col-md-12 text-right">
        <button class="btn btn-primary" @click="downloadContratoCCB">
          DOWNLOAD
          <i class="fas fa-download"></i>
        </button>
      </div>
    </div>
    <div class="download-contrato-loading" v-if="isLoading">
      <double-bounce class="download-contrato-loading-bounce"></double-bounce>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="false"
      :enable-download="false"
      :preview-modal="false"
      filename="Contrato CCB"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a3"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf">

      <section slot="pdf-content" style="padding: 40px;">
        <div class="table-responsive content" v-if="objeto.ecwl_aporte.numero_ccb">
          <div class="row" v-if="!isLoading">
            <div class="col-md-2 col align-self-start">
              <img src="@/assets/images/fiducia_logo.png" class="img-fluid" alt="Fidúcia" v-if="!isBancarizadorManual && !isBancarizadorEagle">
              <img src="@/assets/images/ajx_logo.png" class="img-fluid" alt="Fidúcia" v-if="isBancarizadorEagle">
            </div>
          </div>
          <hr/>
          <div class="row col-md-12 justify-content-center" style="padding-top: 40px; padding-bottom: 40px;">
            <h3 slot="header" class="title" style="font-weight: 600;">
              {{ title }}
            </h3>
          </div>
          <!-- <div>
            <pre>
              {{ JSON.stringify(objeto.ecwl_aporte) }}
            </pre>
          </div> -->
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">CÉDULA Nº</th>
                <th scope="col">VALOR NOMINAL</th>
                <th scope="col">VALOR LÍQUIDO</th>
                <th scope="col">EMISSÃO</th>
                <th scope="col">VENCIMENTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ objeto.ecwl_aporte.numero_ccb }}</td>
                <td>{{ objeto.ecwl_aporte.valor | currency }}</td>
                <td>{{ objeto.simulacao.valor_liberado_nf | currency }}</td>
                <td>{{ objeto.ecwl_aporte.data }}</td>
                <td>{{ objeto.simulacao.parcelas.data_final_vencimento }}</td>
              </tr>

              <tr>
                <td colspan="3">
                  <b>1.1 - EMITENTE</b> <br>
                  {{ objeto.ecwl_oferta.empresa_razao_social }}
                </td>
                <td colspan="2">
                  <b>CPF/CNPJ</b> <br>
                  {{ objeto.ecwl_oferta.empresa_cnpj }}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>ENDEREÇO</b> <br>
                  {{ objeto.ecwl_oferta.empresa_rua }}
                </td>
                <td>
                  <b>CIDADE</b> <br>
                  {{ objeto.ecwl_oferta.empresa_cidade }}
                </td>
                <td>
                  <b>UF</b> <br>
                  {{ objeto.ecwl_oferta.empresa_uf }}
                </td>
              </tr>

            </tbody>
          </table>

          <div class="row col-md-12">
            <b>II. CREDOR ORIGINARIO DORAVANTE (CREDOR)</b>
          </div>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="3">  
                  <b>RAZÃO SOCIAL</b> <br>
                  {{ objeto.credor.razao_social }}
                </td>
                <td colspan="2">
                  <b>CNPJ</b> <br>
                  {{ objeto.credor.cnpj }}
                </td>
              </tr>

              <tr>
                <td colspan="2">  
                  <b>ENDEREÇO</b> <br>
                  {{ objeto.credor.endereco }}
                </td>
                <td>
                  <b>CEP</b> <br>
                  {{ objeto.credor.cep }}
                </td>
                <td>
                  <b>CIDADE</b> <br>
                  {{ objeto.credor.cidade }}
                </td>
                <td>
                  <b>UF</b> <br>
                  {{ objeto.credor.uf }}
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="5">  
                  <b>2.1 - NATUREZA:</b>
                </td>
              </tr>

              <tr>
                <td colspan="5">  
                  {{ objeto.credor.natureza }}
                </td>
              </tr>

              <tr>
                <td colspan="5">  
                  <b>2.2 - VALOR DO CRÉDITO ABERTO POR EXTENSO:</b>
                </td>
              </tr>

              <tr>
                <td colspan="5">  
                  {{ objeto.ecwl_aporte.valor_extenso }}
                </td>
              </tr>

              <tr style="text-align: center;">
                <td>
                  <b>2.3-Taxa (%) de Juros Mensal</b>
                </td>
                <td>
                  <b>2.4-Taxa (%) de Juros Anual</b>
                </td>
                <td>
                  <b>2.5-Juro de Mora - A.M.</b>
                </td>
                <td>
                  <b>2.6-Multa p/Inadimplento</b>
                </td>
                <td>
                  <b>2.7-CET A.A.</b>
                </td>
              </tr>

              <tr style="text-align: right;">
                <td>
                  {{ objeto.ecwl_oferta.taxa_juros }}
                </td>
                <td>
                  {{ objeto.simulacao.taxa_juros_anual }}
                </td>
                <td>
                  {{ objeto.ecwl_oferta.juros_mora }}
                </td>
                <td>
                  {{ objeto.ecwl_oferta.multa_por_inadimplento }}
                </td>
                <td v-if="!isBancarizadorManual">
                  {{ objeto.simulacao.cet_nf | currency }}
                </td>
                <td v-if="isBancarizadorManual">
                  {{ objeto.simulacao.cet_nf }}%
                </td>
              </tr>

              <tr style="text-align: center;">
                <td>
                  <b>Valor do I.O.F</b>
                </td>
                <td>
                  <b>Taxa de Cad.</b>
                </td>
                <td>
                  <b>Valor da Tarifa</b>
                </td>
                <td>
                  <b>Valor da Pendência</b>
                </td>
                <td>
                  <b>Valor Recompra</b>
                </td>
              </tr>

              <tr style="text-align: right;">
                <td>
                   {{ objeto.simulacao.iof_nf | currency }}
                </td>
                <td>
                  0.00
                </td>
                <td>
                   {{ objeto.ecwl_oferta.valor_tarifa | currency }} 
                </td>
                <td>
                   0.00 
                </td>
                <td>
                  0.00 
                </td>
              </tr>

              <tr>
                <td colspan="5">  
                  <b>2.8 - PRAÇA DE PAGAMENTO</b>
                </td>
              </tr>

              <tr>
                <td colspan="5">  
                  {{ objeto.credor.praca_pagamento }}
                </td>
              </tr>
              <tr>
                <td colspan="5">  
                  <b>3.1 - GARANTIA:</b>
                </td>
              </tr>

              <tr>
                <td colspan="5">  
                  Ações Preferenciais – Título Múltiplo Nº 177.341 e 201.071, Quantidade: 3.440.000 + 4.560.000, Classe "A e B" Nominativas, do Banco do Estado de Santa Catarina S/A – BESC, integralizadas ao Banco do Brasil S/A em 11/09/2008, de números 43.392.888.511 a 43.396.328.510 e 62.718.090.841 a 62.722.650.840, perfazendo o valor total atual de R$ 70.000.000,00 (Setenta milhões de Reais).
                </td>
              </tr>
           </tbody>
        </table>
        <div style="page-break-before:always">&nbsp;</div>
        <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <b>3.2 - VALOR:</b>
                </td>
                <td colspan="2">
                  <b>3.3 - FIEL DEPOSITÁRIO:</b>
                </td>
                <td>
                  <b>3.4 - CPF/MF:</b>
                </td>
                <td>
                  <b>3.5 - LOCAL:</b>
                </td>
              </tr>

              <tr>
                <td></td>
                <td colspan="2"></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="5">  
                  <b>4.1 - ENCARGOS PÓS-FIXADOS</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">  
                  <b>4.2 - PARÂMETRO DE REAJUSTE:</b>
                </td>
                <td colspan="3">  
                  <b>4.3 - % DO PARÂMETRO:</b>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <b>4.4 - PERIODICIDADE DE FLUTUAÇÃO:</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>4.5 - TAXA DE JUROS:</b>
                </td>
                <td colspan="3">
                  <b>4.6 - TAXA DE JUROS SUBSTITUTIVA (CLÁUSULA 4a,§ 3a):</b>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <b v-if="objeto.ecwl_oferta.tipo_regime_emitente == 'PRÉ-FIXADO'">5.1 - A (EMITENTE) DECLARA QUE OPTOU PELO REGIME DE: ( X ) PRÉ-FIXADO ( ) PÓS-FIXAÇÃO</b>
                  <b v-if="objeto.ecwl_oferta.tipo_regime_emitente == 'PÓS-FIXAÇÃO'">5.1 - A (EMITENTE) DECLARA QUE OPTOU PELO REGIME DE: ( X ) PRÉ-FIXADO ( ) PÓS-FIXAÇÃO</b>
                  <b v-if="!objeto.ecwl_oferta.tipo_regime_emitente || objeto.ecwl_oferta.tipo_regime_emitente == ''">5.1 - A (EMITENTE) DECLARA QUE OPTOU PELO REGIME DE: ( ) PRÉ-FIXADO ( ) PÓS-FIXAÇÃO</b>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <b>6.1 - FORMA PAGAMENTO: {{ objeto.simulacao.parcelas.numero_parcelas }}</b>
                </td>
              </tr>
              <tr style="text-align: center;">
                <td>
                  <b>Nº da Parcela</b>
                </td>
                <td>
                  <b>Data do Vencimento</b>
                </td>
                <td>
                  <b>Principal em R$</b>
                </td>
                <td>
                  <b>Juros em R$</b>
                </td>
                <td>
                  <b>Total em R$</b>
                </td>
              </tr>
              <tr v-for="parcela in objeto.simulacao.parcelas.lista" :key="parcela.numero_prestacao">
                 <td>{{ parcela.numero_prestacao }}</td>
                <td>{{ parcela.data_pagamento }}</td>
                <td>{{ parcela.saldo_devedor | currency }}</td>
                <td>{{ parcela.juros | currency }}</td>
                <td>{{ parcela.valor_parcelas | currency }}</td>
              </tr>
            </tbody>
          </table>
          
          <div v-html="objeto.contrato.contrato_ccb"></div>
          
          <br><br><br><br>

          <table class="table table-bordered">
            <tbody>
              <tr style="text-align: center;">
                <td colspan="2">Local: {{ objeto.credor.praca_pagamento }}</td>
                <td colspan="2">Data: {{ objeto.ecwl_aporte.data_extenso }}</td>
              </tr>
            </tbody>
          </table>
          <br><br><br><br><br>

          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              EMITENTE: {{ objeto.ecwl_oferta.empresa_razao_social }}<br>
              CPF/CNPJ: {{ objeto.ecwl_oferta.empresa_cnpj }}
            </div>

            <div class="col-md-2"></div>

            <div class="col-md-5">
              <hr>
              CREDORA: {{ objeto.credor.razao_social }}<br>
              CNPJ/MF: {{ objeto.credor.cnpj }}
            </div>
          </div>
          
          <br><br><br>

          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              <b>AVALISTA / DEVEDOR SOLIDÁRIO:</b>
            </div>
          </div>

          <br><br><br>
          
          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              <b>AVALISTA / DEVEDOR SOLIDÁRIO:</b>
            </div>
          </div>

          <br><br><br>
          
          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              <b>AVALISTA / DEVEDOR SOLIDÁRIO:</b>
            </div>
          </div>

          <br><br><br>
          
          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              <b>AVALISTA / DEVEDOR SOLIDÁRIO:</b>
            </div>
          </div>

          <br><br><br><br>

          <div class="row col-md-12 justify-content-center" style="text-align: center;">
            FIEL DEPOSITÁRIO: <br>
            CPF/CNPJ:
          </div>

          <br><br><br><br>

          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              Testemunha:<br>
              CPF/CNPJ:
            </div>

            <div class="col-md-2"></div>

            <div class="col-md-5">
              <hr>
              Testemunha:<br>
              CPF/CNPJ:
            </div>
          </div>

          <br><br><br><br>
          
          <!-- <div class="html2pdf__page-break"/> -->
          <div style="page-break-before:always">&nbsp;</div>

          <div class="row col-md-12 justify-content-center" style="text-align: center;padding-top: 40px;">
            <b>ANEXO - CARTA DE ENDOSSO CCB Nº {{ objeto.ecwl_aporte.numero_ccb }} </b>
          </div>

          <br><br><br>

          <div class="row col-md-12">
            <b>1.CREDOR-ENDOSSANTE doravante ENDOSSANTE</b>
          </div>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="2">
                  <b>RAZÃO SOCIAL</b> <br>
                  {{ objeto.credor.razao_social }}
                </td>
                <td colspan="3">
                  <b>CNPJ</b> <br>
                  {{ objeto.credor.cnpj }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>ENDEREÇO</b> <br>
                  {{ objeto.credor.endereco }}
                </td>
                <td>
                  <b>CEP</b> <br>
                  {{ objeto.credor.cep }}
                </td>
                <td>
                  <b>CIDADE</b> <br>
                  {{ objeto.credor.cidade }}
                </td>
                <td>
                  <b>UF</b> <br>
                  {{ objeto.credor.uf }}
                </td>
              </tr>
            </tbody>
          </table>

          <br>

          <div class="row col-md-12">
            <b>2.INVESTIDOR doravante ENDOSSATÁRIO</b>
          </div>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="3">
                  <b>RAZÃO SOCIAL/NOME</b> <br>
                   {{ objeto.ecwl_aporte.investidor_nome }} 
                </td>
                <td colspan="2">
                  <b>CNPJ/MF/CPF nº</b> <br>
                   {{ objeto.ecwl_aporte.investidor_cpf }} 
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>ENDEREÇO</b> <br>
                   {{ objeto.ecwl_aporte.investidor_endereco }} 
                </td>
                <td>
                  <b>CEP</b> <br>
                   {{ objeto.ecwl_aporte.investidor_endereco_cep }} 
                </td>
                <td>
                  <b>CIDADE</b> <br>
                   {{ objeto.ecwl_aporte.investidor_endereco_cidade }} 
                </td>
                <td>
                  <b>UF</b> <br>
                   {{ objeto.ecwl_aporte.investidor_endereco_uf }}
                </td>
              </tr>
            </tbody>
          </table>

          <br>

          <div class="row col-md-12">
            <b>3.EMITENTE</b>
          </div>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="3">
                  <b>RAZÃO SOCIAL</b> <br>
                  {{ objeto.ecwl_oferta.empresa_razao_social }}
                </td>
                <td colspan="2">
                  <b>CPF/CNPJ</b> <br>
                  {{ objeto.ecwl_oferta.empresa_cnpj }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>ENDEREÇO</b> <br>
                  {{ objeto.ecwl_oferta.empresa_rua }}
                </td>
                <td>
                  <b>CEP</b> <br>
                  {{ objeto.ecwl_oferta.empresa_cep }}
                </td>
                <td>
                  <b>CIDADE</b> <br>
                  {{ objeto.ecwl_oferta.empresa_cidade }}
                </td>
                <td>
                  <b>UF</b> <br>
                  {{ objeto.ecwl_oferta.empresa_uf }}
                </td>
              </tr>
            </tbody>
          </table>

          <br>

          <div class="row col-md-12">
            <b>4.AVALISTA</b>
          </div>

          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="3">
                  <b>NOME/RAZÃO SOCIAL</b> <br><br>
                  
                </td>
                <td colspan="2">
                  <b>CPF/CNPJ</b> <br><br>
                  
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>ENDEREÇO</b> <br><br>
                  
                </td>
                <td>
                  <b>CEP</b> <br><br>
                  
                </td>
                <td>
                  <b>CIDADE</b> <br><br>
                  
                </td>
                <td>
                  <b>UF</b> <br><br>
                  
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <b>CEDULA DE CREDITO BANCARIA:</b>  {{ objeto.ecwl_aporte.numero_ccb }}
                </td>
              </tr>
              <tr>
                <td>
                  <b>DATA DE EMISSAO</b><br>
                   {{ objeto.ecwl_aporte.data }}
                </td>
                <td colspan="2">
                  <b>DATA FINAL DE VENCIMENTO</b><br>
                   {{ objeto.simulacao.parcelas.data_final_vencimento }}
                </td>
                <td>
                  <b>VALOR PRINCIPAL</b><br>
                   {{ objeto.ecwl_aporte.valor | currency }}
                </td>
                <td>
                  <b>PRAZO DE AMORTIZAÇÃO</b><br>
                  {{ objeto.simulacao.parcelas.numero_parcelas }}
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <div v-html="objeto.contrato.anexo_contrato_ccb_avalista"></div>
                </td>
              </tr>
            </tbody>
          </table>

          <br>
          <div style="page-break-before:always">&nbsp;</div>
          <div v-html="objeto.contrato.anexo_contrato_ccb"></div>

          <br>

          <div class="row col-md-12">
            <b>Assinaturas:</b>
          </div>
          
          
          <div class="row col-md-12">
            <span style="font-style: italic;">Documento assinado digitalmente</span>
          </div>

          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
               {{ objeto.credor.razao_social }} (Endossante)
            </div>
          </div>
          
          <br><br>


          <div class="row col-md-12">
            <span style="font-style: italic;">Documento assinado digitalmente</span>
          </div>

          <div class="row" style="text-align: center;">
            <div class="col-md-5">
              <hr>
              {{ objeto.ecwl_aporte.investidor_nome }} (Endossatário) <br/>
              CPF: {{ objeto.ecwl_aporte.investidor_cpf }} - 
              Login: {{ objeto.ecwl_aporte.investidor_email }} | IP: {{ objeto.ecwl_aporte.investidor_ip }} <br/>          
            </div>
          </div>
          <div class="row" style="text-align: center;">
            <div class="col-md-12">
              <br><br><br>
              Carimbo de tempo:  {{ objeto.ecwl_aporte.data }} - {{ objeto.ecwl_aporte.hora }}:00 | 
              Hash de Assinatura:  {{ objeto.ecwl_aporte.hash_assinatura }}  <br/>
            </div>
          </div>
          <br><br>
          <div style="page-break-before:always">&nbsp;</div>

          <div class="row" style="text-align: left; margin-top: 90px">
              <div style="display: flex; align-items: center; justify-content: space-around;">
                  <div class="col-md-5">
                    Documento assinado digitalmente conforme MP 2.200-2/01 e Lei 14.063/20. Para validar a legitimidade on-line acesse <a href="https://validar.iti.gov.br" target="_blank">https://validar.iti.gov.br</a><br>
                  </div>
                  <img src="@/assets/images/sign-selo.jpeg"
                  width="200" />
              </div>
          </div>
        </div> 
      </section>
    </vue-html2pdf>
  </div>
</template>

<style lang="scss" scoped>
  .download-contrato {
    position: absolute;
    right: 64px;
    top: 10px;

    > div {
      
      > button {
        font-weight: 600;
        padding: 5 10 5 10;
      }
    }
  }
  .download-contrato-loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.95);
    width: 100%;
    height: 388px;
    top: 0;
    left: 0;
    z-index: 999;
    text-align: center;

    .download-contrato-loading-bounce {
      top: 200px;
    }
  }
</style>