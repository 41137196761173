<script>
import settingsService from '@/services/Settings/settingsService';
import dynamicFieldsService from '@/services/DynamicFields/dynamicFieldsService';
import {CWLStatusInvestidor} from '@/store/cwl_enums';

export default {
  name: 'investment-informations',
  props: {
    financing: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      allSettings: {},
      quantidadeParaContribuir: 0,
      valorParaContribuir: 0,
      sortedTopics: [],
      modalidade: process.env.VUE_APP_CHECKOUT
    };
  },
  async mounted() {
    this.getSettings();
    await this.mountSortedTopics();
  },
  computed: {
    user() {
      return this.$store.getters.getPessoa;
    }
  },
  methods: {
    async mountSortedTopics() {
      let dynamicFields = await dynamicFieldsService.getAllPublicWithKeys();
      
      let sorted = Object.keys(dynamicFields)
        .map(t => {
          if ( this.financing.fields[t] ) {
            this.$set(this.financing.fields[t], 'key', t);
            return this.financing.fields[t];
          }
        })
        .sort(this.sortProperty('ordenacao'));
      this.sortedTopics = sorted.filter( df => df != undefined);
    },
    sortProperty(prop){
      return (a,b) => {
        if (a[prop] < b[prop])
          return -1;
        if (a[prop] > b[prop])
          return 1;
        return 0;       
      }
    },
    async getSettings() {
      this.allSettings = await settingsService.getPublicSettings();
    },
    nextStep() {

      if ( this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.EmAnalise ) {
        this.$toasted.show('Cadastro em análise. Por favor, aguarde a aprovação. Você será notificado por e-mail.', {
          icon: 'times',
          className: 'error',
        });
        return false;
      }
      else if ( this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.Incompleto 
      || this.user.dados_complementares.approvalStatus == CWLStatusInvestidor.Reprovado ) {
        this.$toasted.show('Cadastro precisa ser revisado. Por favor, verifique seus dados cadastrais.', {
          icon: 'times',
          className: 'error',
        });
        this.redirectToProfile();
      }
      
      this.$emit('updateInformations');

      let minValue = 0;

      if (!this.allSettings.overwriteMinGlobalInvestment) {
        minValue = this.allSettings.minGlobalInvestment;
      } else {
        const fieldMinValue = this.financing.fields['Investimento Mínimo Permitido'];
        minValue = fieldMinValue ? fieldMinValue.value : 0;
      }

      const fieldValorAcao = this.financing.fields['Valor da Ação'];
      const fieldValueValorAcao = fieldValorAcao.value;
      // if ( ( this.quantidadeParaContribuir * fieldValueValorAcao ) > minValue) {
      if ( this.valorParaContribuir > minValue ) {
        const item = this.financing;
        const order = {};

        order.unitValue = fieldValueValorAcao;
        // order.totalValue = this.quantidadeParaContribuir * fieldValueValorAcao;
        order.totalValue = this.valorParaContribuir;
        // order.quantityOfQuotas = this.quantidadeParaContribuir;
        order.quantityOfQuotas = 1;

        item.order = order;

        this.$store.commit('SET_ITEM', item);
        this.$emit('nextStep');
      } else {
        this.$toasted.show(
          `O Valor da contribuição deve ser acima de R$ ${minValue}`,
          {
            icon: 'times',
            className: 'error'
          }
        );
      }
    }
  }
};
</script>

<template>
  <div class="investment-columns">
    <div class="investment-quantity">
      <h4>Informações sobre o Contrato</h4>
      <p>
        Para prosseguir é necessário revisar as informações sobre a oferta e informar a quantidade de cotas que você deseja adquirir.
      </p>

      <!-- <div class="quotas-quantity">
        <label for="valorParaContribuir">Quantidade de Cotas Desejadas*</label>
        <input
          id="quotasQuantity"
          v-model="quantidadeParaContribuir"
          type="number"
          min="1"
          :auto-decimal-mode="true"
        />
      </div> -->

      <div class="quotas-quantity">
        <label for="valorParaContribuir">Valor do aporte*</label>
        <currency-input
          id="valorParaContribuir"
          class="form-control"
          v-model="valorParaContribuir"
          currency="BRL"
          locale="pt-br"
          :required="true"
          :auto-decimal-mode="true"
          :distraction-free="false"
          style="width: 200px;"
        />
      </div>
    </div>

    <div class="investment-informations">

      <div class="card">
        <img :src="financing.imageURL" class="card-img-top">
        <div class="card-body">
          <h6 class="card-subtitle text-muted text-center" style="font-size: 15px; margin-top: 5px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><b>{{ financing.name }}</b></h6>
          <br />
          <div class="alert" role="alert" style="cursor: pointer; background-color: #2d74ae;" @click="nextStep()" :class="{ 'is-disabled': valorParaContribuir <= 0 }">
            <h5 class="card-subtitle text-muted text-center" style="color: #fff !important; margin-top: 0px;">
              PROSSEGUIR
              <i class="fas fa-arrow-right"></i>
            </h5>
            <!-- <h5 class="card-subtitle text-muted text-center" style="color: #fff !important; margin-top: 0px;">{{ financing.categoria.name }}</h5> -->
          </div>

          <!--<p class="card-text text-right" v-if="!financing.is_empresa">
            <i class="fa fa-user-o" aria-hidden="true" v-tooltip="'Esta oferta é para uma pessoa física.'"></i> {{ financing.users.created_by.nome }}
          </p>
          <p class="card-text text-right" v-if="financing.is_empresa">
            <i v-if="modalidade == 'doacao'" class="fa fa-heart-o" aria-hidden="true" v-tooltip="'Esta oferta é para uma entidade sem fins lucrativos.'"></i> {{ financing.dados_complementares.empresa.nome }}
          </p>
          <p class="card-text text-right" style="font-size: 12px;">
            {{ financing.cidade.nome }} / {{ financing.estado.name }}
          </p>-->

          <hr style="border-top-color: #6c757d; opacity: 0.1;">

          <p class="card-text text-left text-muted" style="text-transform: uppercase;">
            Valor Já Reservado <span style="font-weight: bolder;">{{ financing.totais.valor_total_formatado }}</span>
          </p>

          <div class="progress" style="height: 1.5rem; background-color: #e9ecef; border-radius: 0.25rem; -webkit-box-shadow: none; box-shadow: none; margin-top: 10px; margin-bottom: 10px;">
            <div v-if="financing.totais.porcentagem_investido < 6" class="progress-bar bg-info" role="progressbar" :style="'color: #000; width: '+ financing.totais.porcentagem_investido + '%;'" :aria-valuenow="financing.totais.porcentagem_investido" aria-valuemin="0" aria-valuemax="100">
              {{ financing.totais.porcentagem_investido_formatado }}
            </div>
            <div v-if="financing.totais.porcentagem_investido >= 6" class="progress-bar bg-info" role="progressbar" :style="'color: #fff; width: '+ financing.totais.porcentagem_investido + '%;'" :aria-valuenow="financing.totais.porcentagem_investido" aria-valuemin="0" aria-valuemax="100">
              {{ financing.totais.porcentagem_investido_formatado }}
            </div>
          </div>

          <p class="card-text text-right text-muted">
            Alvo <span style="font-weight: bolder;">{{ financing.totais.valor_meta_formatado }}</span>
          </p>

          <p v-if="financing.diasRestante" class="card-text text-right">
            <small class="text-muted">
              <i class="fa fa-clock-o" aria-hidden="true"></i> {{ financing.diasRestante }} dias restantes
            </small>
          </p>

          <div class="col-md-12" v-for="field in sortedTopics" :key="field.id" style="text-transform: uppercase; color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important; margin-bottom: 15px;">
              <label>{{ field.label }}</label>
              <currency-input
                v-if="field.type == 'currency'"
                :id="field.id"
                class="form-control"
                v-model="field.value"
                :placeholder="field.placeholder"
                :required="field.required"
                currency="BRL"
                locale="pt-br"
                :auto-decimal-mode="true"
                :distraction-free="false"
                readonly
                disabled
                style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
              />
              <input
                v-if="field.type != 'currency' && field.type != 'percent' && field.reference != 'Encerramento da Oferta'"
                :type="field.type"
                v-model="field.value"
                readonly
                disabled
                class="form-control"
                style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
              />
              <input
                v-if="field.type != 'currency' && field.type == 'percent'"
                :type="field.type"
                v-model="field.value"
                v-mask="['#%', '##%', '###%']"
                readonly
                disabled
                class="form-control"
                style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
              />
              <input
                v-if="field.reference == 'Encerramento da Oferta'"
                :type="field.type"
                v-model="field.value"
                readonly
                disabled
                class="form-control"
                style="color: #6c757d !important; font-weight: 300 !important; font-size: 0.875rem !important; font-family: 'Poppins', sans-serif !important;"
              />
          </div>
        </div>
      </div>

      <div class="card-footer">

        <button
          class="button"
          @click="nextStep()"
          :class="{ 'is-disabled': valorParaContribuir <= 0 }"
        >
          Prosseguir
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .card {
    padding: 10px 18px;
    box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
    background: #f5f6fa;
    border: 0;
    margin: 2px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    border-radius: 0.2857rem;
  }
  .card-img-top {
    width: 512px;
    height: 342px;
    border-top-left-radius: calc(0.2857rem - 0.0625rem);
    border-top-right-radius: calc(0.2857rem - 0.0625rem);
  }

  img {
    max-width: 100%;
    border-radius: 0.2857rem;
    vertical-align: middle;
    border-style: none;
    max-height: 200px !important;
    width: auto !important;
  }

  .card .card-body {
    padding: 15px;
  }

  h6, .h6 {
    text-transform: uppercase;
    font-weight: 500;
  }

  .alert.alert-success {
    background-color: #00bf9a;
  }

  .alert {
    border: 0;
    color: #ffffff;
  }

  .alert {
    position: relative;
    padding: 0.9rem 1.25rem;
    margin-bottom: 1rem;
    border: 0.0625rem solid transparent;
    border-radius: 0.2857rem;
  }

  h5, .h5 {
    font-size: 0.8125rem;
  }

  .card-text {
    color: #333;
    font-weight: 300;
    font-size: 0.875rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
  }
</style>