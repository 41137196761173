import axios from 'axios';

export default {
  async sendOrder(email, content) {
    await axios.post(
      'https://us-central1-crowdfunding-wl-qas.cloudfunctions.net/sendPaymentFinished',
      {
        email: email,
        html: content
      }
    );
  }
};
