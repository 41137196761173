<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'Home',
  components: {
    VueTyper
  },
  data() {
    return {
      registerPath: process.env.VUE_APP_ROOT_PORTAL + 'register',
      projectsEnv: process.env.VUE_APP_HOME,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    goToRegister() {
      window.location.replace(this.registerPath);
    },
  }
};
</script>

<template>
  <div class="home">
    <div class="container">
      <h1 class="home-title">
        <vue-typer :text="['Faça Doações', 'Invista em Startups']"></vue-typer>
      </h1>
      <p class="home-description" style="text-align: center;">
        Seja sócio de empresas inovadoras através de nossa plataforma.<br />
        Seja doador de campanhas de doação em causas sociais.
      </p>

      <div class="home-buttons">
        <router-link :to="projectsEnv" tag="button" class="button is-inverted">
          Conheça Projetos
        </router-link>
      </div>
    </div>
  </div>
</template>
