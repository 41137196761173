import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Projects from '../views/Projects.vue';
import ProjectDetail from '../views/ProjectDetail.vue';
import ProjectThanks from '../views/ProjectThanks.vue';
import NewInvestment from '../views/NewInvestment.vue';
import ListaOfertas from '../views/ListaOfertas.vue';
import PaymentCheckout from '../views/PaymentCheckout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/investimentos',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/investimentos/detalhes/:id',
    name: 'Project Detail',
    component: ProjectDetail,
  },
  {
    path: '/investimentos',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/investimentos/detalhes/:id',
    name: 'Project Detail',
    component: ProjectDetail,
  },
  {
    path: '/project-detail/:id',
    name: 'Project Detail',
    component: ProjectDetail,
  },
  {
    path: '/project/:id/payment-checkout',
    name: 'Project Payment Checkout',
    component: PaymentCheckout,
  },
  {
    path: '/project/:id',
    name: 'Project Detail',
    component: ProjectDetail,
  },
  {
    path: '/obrigado/:oid',
    name: 'Project Thanks',
    component: ProjectThanks,
  },
  {
    path: '/divulgue/:id',
    name: 'Project Share',
    component: ProjectDetail,
  },
  {
    path: '/new-investment/:id',
    name: 'New Investment',
    component: NewInvestment,
  },
  {
    path: '/new-investment/:id/:admin',
    name: 'New Investment',
    component: NewInvestment,
  },
  {
    path: '/lista-ofertas',
    name: 'Lista de Ofertas',
    component: ListaOfertas,
  },
  {
    path: '/lista-ofertas/:status',
    name: 'Lista de Ofertas',
    component: ListaOfertas,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   console.log(Object.keys(store.getters.isLoggedIn));
//   console.log(Object.keys(store.getters.getPessoa));

//   next();
// });

export default router;
