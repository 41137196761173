<script>
import financingService from '@/services/Financing/financingService';
import sendEmail from '@/services/Functions/sendEmail';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'finish-screen',
  components: {
    DoubleBounce
  },
  data() {
    return {
      screenDetails: [],
      isLoading: true,
      envPath: process.env.VUE_APP_ROOT_PORTAL
    };
  },
  computed: {
    financingId() {
      return this.$route.params.id;
    },
    loggedInUser() {
      return this.$store.getters.isLoggedIn;
    },
    paymentInformations() {
      return this.$store.getters.getOrder;
    }
  },
  mounted() {
    this.getFinancialDetail();
  },
  methods: {
    async getFinancialDetail() {
      this.financialDetails = await financingService.findById(this.financingId);

      const parsedContent = await this.financialDetails.relevantInformations;

      document.getElementById('parsedContent').innerHTML = parsedContent;

      if (this.paymentInformations.payment.BankSlipUrl) {
        const taxInformations = {
          DigitableLine: this.paymentInformations.payment.DigitableLine,
          BankSlipUrl: this.paymentInformations.payment.BankSlipUrl
        };

        const taxInformationsParsed = `
          ${parsedContent} <br>
          <h4>Para realizar o Pagamento de seu Boleto, utilize a seguinte linha
        digitável:</h4>
          ${taxInformations.DigitableLine} <br> Ou então abra em PDF pelo link: <br>
          ${taxInformations.BankSlipUrl}
        `;

        this.sendEmail(this.loggedInUser, taxInformationsParsed);
      } else {
        this.sendEmail(this.loggedInUser, parsedContent);
      }

      this.isLoading = false;
    },
    sendEmail(user, content) {
      sendEmail.sendOrder(user.email, content);
    },
    goToDashboard() {
      window.location.replace(this.envPath);
    }
  }
};
</script>

<template>
  <div class="finish-screen">
    <double-bounce v-if="isLoading"></double-bounce>

    <div id="parsedContent"></div>

    <div
      class="tax-informations"
      v-if="paymentInformations.payment.BankSlipUrl"
    >
      <h4>
        Para realizar o pagamento de seu boleto, utilize ou <b>clique</b> na
        seguinte linha digitável:
      </h4>

      <a :href="paymentInformations.payment.BankSlipUrl">
        {{ paymentInformations.payment.DigitableLine }}
      </a>
    </div>

    <div class="redirect-button" @click="goToDashboard()">
      <button class="button">
        Acessar Plataforma
        <i class="fas fa-arrow-right"></i>
      </button>
    </div>
  </div>
</template>
