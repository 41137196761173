import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import Notifications from 'vue-notification';
import { BootstrapVue } from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import Toasted from 'vue-toasted';

// Styles
import '@/assets/scss/main.scss';

require('../node_modules/vue-birth-datepicker/dist/vueBirthDatepicker.css');

Vue.config.productionTip = false;
// Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(Toasted, {
  iconPack: 'fontawesome',
  duration: 2500
});

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
import '@/assets/css/validate-styles.css';


Vue.filter('currency', function(value) {
  let val = parseFloat(value).toFixed(2).replace('.', ',');
  return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('percentage', function(value) {
  return parseFloat(value).toFixed(2) + ' %';
});

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'BRL', locale: 'pt-BR' }
};
Vue.use(VueCurrencyInput, pluginOptions);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
