<script>
// import Vue from 'vue';
import { DoubleBounce } from 'vue-loading-spinner';

import CategoryTag from '@/components/category/CategoryTag';
import FinancingTitle from '@/components/financing/FinancingTitle';
import ContributionPublicField from '@/components/financing/ContributionPublicField';
import PaymentAmountForm from '@/components/payment/PaymentAmountForm';
import PaymentMethod from '@/components/payment/PaymentMethod';
import PaymentCompleted from '@/components/payment/PaymentCompleted';
import AcceptTermsOfUsageField from '@/components/terms/AcceptTermsOfUsageField';
import UserIdentityForm from '@/components/user/UserIdentityForm';

import financingService from '@/services/Financing/financingService';
import settingsService from '@/services/Settings/settingsService';
import { makePayment } from '@/services/Functions/makePayment';

import { formatCurrency } from '@/formatter/NumberFormatter';

import moment from 'moment';

const PaymentCheckoutSteps = {
  LOGIN: 'LOGIN',
  PAYMENT_AMOUNT: 'PAYMENT_AMOUNT',
  PAYMENT_METHOD: 'PAYMENT_METHOD',
  COMPLETED: 'COMPLETED',
};

export default {
  name: 'payment-checkout',
  components: {
    DoubleBounce,
    CategoryTag,
    FinancingTitle,
    PaymentAmountForm,
    PaymentMethod,
    UserIdentityForm,
    PaymentCompleted,
    AcceptTermsOfUsageField,
    ContributionPublicField,
  },
  data() {
    return {
      step: undefined,
      loading: true,
      paymentLoading: false,
      financing: undefined,
      paymentSettings: {
        minAmount: 0.01,
      },
      user: {},
      isPublic: false,
      acceptTerms: false,
      payment: {},
      response: {},
    };
  },
  validations() {
    return {
      payment: {
        amount: {
          valid: value => {
            return value >= this.paymentSettings.minAmount;
          },
        },
      },
      acceptTerms: {
        valid: value => value === true,
      },
    };
  },
  async mounted() {
    try {
      const id = this.$route.params.id;
      this.financing = await financingService.findById(id);
      this.settings = await settingsService.getPublicSettings();

      const minAmount = this.settings.minGlobalInvestment || 0;

      this.paymentSettings = {
        minAmount: minAmount,
      };

      const user = this.$store.getters.getPessoa;
      this.user = user.pessoa_id
        ? {
            displayName: user.nome,
            email: user.email?.email,
            birthday: user.dados_complementares?.birthday,
            documentNumber: user.documento?.numero,
          }
        : undefined;
      if (!this.user) {
        this.redirectToLogin();
      } else {
        this.step = PaymentCheckoutSteps.PAYMENT_AMOUNT;
      }
    } catch (error) {
      console.error('load checkout error', error);
    }
    this.loading = false;
  },
  methods: {
    async makePayment(value) {
      this.scrollToStepperContainer();

      try {
        this.paymentLoading = true;
        this.payment = value;
        this.response = await makePayment({
          financingId: this.financing.id,
          user: this.user,
          value: value.amount || 0,
          paymentKind: value.paymentKind,
          address:
            value.paymentKind === 'creditcard'
              ? undefined
              : {
                  postalCode: value.address.cep,
                  publicPlace: value.address.publicPlace,
                  number: value.address.number,
                  complement: value.address.complement,
                  district: value.address.district,
                  city: value.address.city,
                  state: value.address.state,
                },
          creditCard:
            value.paymentKind === 'creditcard' ? value.creditCard : undefined,
          bankSlip:
            value.paymentKind === 'bankslip'
              ? {
                  dueDate: this.bankslipDueDate(),
                  cancelAfterDue: false,
                  isEnablePartialPayment: false,
                  instruction:
                    'Pague em qualquer agência bancária, lotérica ou internet banking.',
                }
              : undefined,
        });

        this.step = PaymentCheckoutSteps.COMPLETED;
      } catch (error) {
        this.alertError(error.message);
        console.error('make payment error', error);
      }
      this.paymentLoading = false;
    },
    bankslipDueDate() {
      const today = new Date();
      let dueDate = new Date();
      let endAt = moment(this.financing.dates.end_at, 'YYYY/MM/DD');
      let diffDays = endAt.diff(today, 'days');
      if (diffDays >= 7) {
        dueDate.setDate(today.getDate() + 7);
      } else {
        dueDate.setDate(today.getDate() + diffDays);
      }
      return dueDate.toLocaleString('pt-br').split(' ')[0];
    },
    alertError(message) {
      this.$toasted.show(message, {
        icon: 'times',
        className: 'error',
        duration: 10 * 1000,
      });
    },
    formatCurrency: formatCurrency,
    handlePaymentAmount(value) {
      this.payment = {
        ...this.payment,
        amount: value.amount,
      };
    },
    redirectToLogin() {
      const id = this.$route.params.id;
      this.$store.commit('SET_PAYMENT_CHECKOUT', { financingId: id });

      const portalUrl = process.env.VUE_APP_ROOT_PORTAL_SINGIN;
      window.top.location.href = portalUrl + '?ref=site&id=' + id;
    },
    handleNextStep(step) {
      this.step = step;
      this.scrollToStepperContainer();
    },
    scrollToStepperContainer() {
       this.$refs.stepperContainer.scrollIntoView({behavior: 'smooth'});
    },
  },
};
</script>
<template>
  <div style="overflow-x: hidden;">
    <double-bounce v-if="loading"></double-bounce>
    <div v-if="!loading">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="card" style="margin-left: 10px; margin-right: 10px;">
            <div class="text-center">
              <img
                :src="financing.imageURL"
                class="card-img-top"
                :alt="financing.name"
              />
            </div>

            <div class="card-body">
              <financing-title :title="financing.name" />
              <category-tag :category="financing.categoria" />
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-8" ref="stepperContainer">
          <double-bounce v-if="!step"></double-bounce>

          <div
            v-if="step == 'PAYMENT_AMOUNT'"
            class="card"
            style="margin-right: 10px; margin-left: 10px;"
          >
            <payment-amount-form
              :error="
                $v.payment.amount.$invalid
                  ? 'Informe um valor igual ou superior a ' +
                    formatCurrency(paymentSettings.minAmount)
                  : ''
              "
              :amount="payment.amount"
              @change="handlePaymentAmount"
            />

            <contribution-public-field
              :checked="isPublic"
              @change="value => (isPublic = value)"
            />

            <accept-terms-of-usage-field
              :checked="acceptTerms"
              @change="value => (acceptTerms = value)"
            />

            <user-identity-form :readonly="true" :initialValue="user" />

            <button
              class="btn btn-success btn-lg col-md-12"
              style="background-color: #17a2b8; border-color: #17a2b8;"
              v-on:click="handleNextStep('PAYMENT_METHOD')"
              :disabled="$v.payment.$invalid || $v.acceptTerms.$invalid"
            >
              CONTINUAR
            </button>
          </div>

          <div
            v-if="step == 'PAYMENT_METHOD'"
            class="card"
            style="margin-right: 10px; margin-left: 10px;"
          >
            <div v-if="paymentLoading">
              <h3 slot="header" class="title">
                Processando pagamento, aguarde.
              </h3>
              <double-bounce></double-bounce>
            </div>

            <div v-if="!paymentLoading">
              <div style="margin-bottom: 20px; cursor: pointer;">
                <span @click="handleNextStep('PAYMENT_AMOUNT')">
                  <i class="fas fa-arrow-left"></i>
                  Voltar
                </span>
              </div>
              <payment-method
                :initialData="payment"
                @make-payment="makePayment"
              />
            </div>
          </div>

          <div
            v-if="step == 'COMPLETED'"
            class="card"
            style="margin-right: 10px; margin-left: 10px;"
          >
            <payment-completed
              :value="formatCurrency(payment.amount)"
              :paymentKind="payment.paymentKind"
              :barcode="response.barcode"
              :barcodeUrl="response.barcodeUrl"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
