<script>
import birthDatepicker from 'vue-birth-datepicker';

export default {
  name: 'user-identity-form',
  props: {
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    initialValue: {
      type: Object,
      required: false,
    },
  },
  components: {
    birthDatepicker,
  },
  data() {
    return {
      value: {
        ...this.initialValue,
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="formNomeCompleto">Nome completo*</label>
        <input
          id="formNomeCompleto"
          type="text"
          class="form-control"
          placeholder="Digite seu nome completo"
          v-model="value.displayName"
          :disabled="readonly"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="formEmail">E-mail*</label>
        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <div class="input-group-text">@</div>
          </div>
          <input
            id="formEmail"
            type="email"
            class="form-control fix-rounded-right"
            placeholder="Digite seu melhor e-mail"
            v-model="value.email"
            :disabled="readonly"
          />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="formNascimento">Data de nascimento*</label>
        <birth-datepicker
          id="formNascimento"
          v-model="value.birthday"
          :valueIsString="true"
          :high="true"
          :hideHeader="true"
          :attachment="'bottom'"
          :delimiter="'/'"
          :placeholder="'Digite seu nascimento. Ex: 27/12/1993'"
          :locale="[
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
          ]"
          :disabled="readonly"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="formCPF">CPF*</label>
        <input
          id="formCPF"
          type="text"
          class="form-control"
          placeholder="Digite seu CPF"
          aria-describedby="cpfHelp"
          v-mask="'###.###.###-##'"
          v-model="value.documentNumber"
          :disabled="readonly"
        />
      </div>
    </div>
  </div>
</template>
