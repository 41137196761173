<script>
import { SweetModal } from 'sweet-modal-vue';

import settingsService from '@/services/Settings/settingsService';

export default {
  name: 'accept-terms-of-usage-field',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      value: this.checked || false,
      terms: '',
    };
  },
  components: {
    SweetModal,
  },
  async mounted() {
    try {
      this.settings = await settingsService.getPublicSettings();
      const terms = this.settings.terms
        ? this.settings.terms.condicoes_termos_uso
        : '';
      this.terms = terms || '';
    } catch (error) {
      console.error('mount company partner form error', error);
    }
  },
  methods: {
    openTerms() {
      this.$refs.termsModal.open();
    },
  },
};
</script>
<template>
  <div>
    <sweet-modal ref="termsModal">
      <div v-html="terms" style="text-align: left;"></div>
    </sweet-modal>
    <div class="form-row">
      <div class="form-group form-check" style="padding-left: 25px;">
        <input
          type="checkbox"
          class="form-check-input"
          id="formAceiteTermos"
          v-model="value"
          v-on:input="$emit('change', !checked)"
        />
        <label class="form-check-label" for="formAceiteTermos"
          >Li e concordo com as </label
        ><span style="color: blue; cursor: pointer; " @click="openTerms">
          Condições e Termos de Uso</span
        >

        <div v-if="value == false" style="color: red">
          Para fazer a doação, é preciso aceitar os termos
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
