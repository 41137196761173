<script>
export default {
  name: 'category-tag',
  props: {
    category: {
      type: Object,
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <div
    class="alert alert-dark"
    role="alert"
    v-if="category"
    style="margin-bottom: 25px;"
  >
    <h5 class="card-subtitle text-muted text-center" style="margin-top: 0px; ">
      {{ category.name }}
    </h5>
  </div>
</template>
