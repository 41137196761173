import axios from 'axios';
import { uuid } from 'vue-uuid';

export default {
  simulate(objeto) {
    const endpoint =
        process.env.VUE_APP_ENDPOINT_FIDUCIA + 'api/v1/bancarizacao/simular';
    const payload = {
      tipo_pessoa: objeto.tipo_pessoa,
      valor_liberado: objeto.valor_liberado,
      primeiro_vencimento: objeto.primeiro_vencimento,
      parcelas: objeto.parcelas,
      taxa_juros: objeto.taxa_juros,
      periodicidade: objeto.periodicidade,
      TAC: objeto.TAC
    };
    const headers = {
      "user": process.env.VUE_APP_FIDUCIA_HEADER_USER
    };
    const auth = {
      username: process.env.VUE_APP_FIDUCIA_AUTH_USER,
      password: process.env.VUE_APP_FIDUCIA_AUTH_PWD
    }
    try {
      return axios.post(endpoint, payload, { headers }, auth).then(r => r.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  generateCCB() {
    return uuid.v4();
  },

  generateHashAssinatura() {
    return uuid.v4();
  },

  generateHash() {
    return uuid.v4();
  }
};
