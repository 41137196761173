<script>
import { Bar } from 'vue-chartjs/legacy';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Finance } from 'financejs';
import VueSlideBar from 'vue-slide-bar';
import { formatCurrency } from '@/formatter/NumberFormatter';
import settingsService from '@/services/Settings/settingsService';

import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
Chart.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
);
const finance = new Finance();

var jurosComparativoAno = "";
export default {
  name: 'simulador',
  components: { Bar, VueSlideBar },
  props: {
    minValue: { type: Number, default: 1000 },
    maxValue: { type: Number, default: 400000 },
    sliderMultipleValuesOf: { type: Number, default: 1000 },
    numOfPeriod: { type: Number, required: true, default: 12 },
    financingName: { type: String, required: true },
    rate: { type: Number, required: true },
    taxaJurosAno: { type: Number, required: true },
  },
  data() {
    return {
      settings: {},
      value: this.minValue,
      futureValue: undefined,
      sliderOptions: {
        data: [],
      },
      chartData: {
        labels: ['', 'CDI', 'Poupança'],
        datasets: [
          {
            data: []
          }],
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            labels: {
              generateLabels() {
                let barLabels = [jurosComparativoAno, 'CDI (11.65% a.a)', 'Poupança (8.15% a.a)'];
                let barBackgroundColors = ['#2F46E0', '#6937F3', '#120F0B'];

                return barLabels.map((label, index) => ({
                  text: label,
                  fillStyle: barBackgroundColors[index]
                }))
              },
            }
          },
          datalabels: {
            align: 'center',
            anchor: 'center',
            color: 'white',
            formatter: value => formatCurrency(value),
          },
        },
        tooltips: { enabled: false },
      },
    };
  },
  async mounted() {
    this.settings = await settingsService.getPrivateSettings();
    jurosComparativoAno = this.settings.platformName + " (" + this.taxaJurosAno.toFixed(2) + "% a.a)";
    this.sliderOptions.data = this.createSliderDataValues();
    //start with min value
    this.value = this.minValue;
    this.onValueChanged(this.minValue);
  },
  methods: {
    formatCurrency: formatCurrency,
    createSliderDataValues() {
      var sliderValue = undefined;
      const sliderLength =
        (this.maxValue - this.minValue) / this.sliderMultipleValuesOf + 1;
      return Array.from({ length: sliderLength }, () => {
        sliderValue =
          sliderValue === undefined
            ? this.minValue
            : sliderValue + this.sliderMultipleValuesOf;
        return formatCurrency(sliderValue);
        // return sliderValue;
      });
    },
    roiValue({ rate }) {
      var newValue = this.value;
      if (typeof this.value === 'string') {
        newValue = newValue.replace("R$ ", "");
        newValue = newValue.replace(".", "");
        newValue = newValue.replace(",00", "");
        newValue = Number(newValue);
      }

      const value = finance.FV(rate, newValue, this.numOfPeriod) - newValue;
      return Number(
        parseFloat(Math.round(value.toString() * 100) / 100).toFixed(2),
      );
    },
    onValueChanged(value) {
      if (typeof value === 'string') {
        value = value.replace("R$ ", "");
        value = value.replace(".", "");
        value = value.replace(",00", "");
        value = Number(value);
      }

      const roi = this.roiValue({ rate: this.rate });
      const total = value + roi;
      this.futureValue = formatCurrency(total);
      const labelFinancing = this.financingName;
      this.chartData = {
        labels: [labelFinancing, 'CDI', 'Poupança'],
        datasets: [
          {
            data: [
              roi,
              this.roiValue({ rate: this.settings.CDI_RATE }),
              this.roiValue({ rate: this.settings.POUPANCA_RATE }),
            ],
            backgroundColor: ['#2F46E0', '#6937F3', '#120F0B'],
          },
        ],
      };
    },
  },
};
</script>

<style>
.simuladorField {
  margin-bottom: 40px;
}
</style>

<template>
  <div class="card" style="margin-left: 10px; margin-right: 10px;">
    <div class="card-body">
      <div style="margin-bottom: 40px; text-align: center;">
        <h2>
          Simule sua contratação
        </h2>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="simuladorField">
            <h4>
              Retorno ao final da contratação
            </h4>
            <div>{{ futureValue }}</div>
          </div>

          <div class="simuladorField">
            <h4>
              Quanto deseja contratar
            </h4>
            <div style="font-size: 12px">
              Valores múltiplos de
              {{ formatCurrency(sliderMultipleValuesOf, { prefix: false }) }}
              entre {{ formatCurrency(minValue) }} a
              {{ formatCurrency(maxValue) }}
            </div>
            <VueSlideBar
              v-model="value"
              :data="sliderOptions.data"
              v-on:input="onValueChanged"
            />
          </div>

          <div class="simuladorField">
            <h4>
              Vencimento do título
            </h4>
            <div>{{ numOfPeriod }} meses</div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <h4 css="text-center">Remuneração</h4>
          <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            chart-id="chartId"
            dataset-id-key="label"
            :width="300"
            :height="300"
          />
        </div>
      </div>
    </div>
  </div>
</template>