<script>
import financingService from '@/services/Financing/financingService';
import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
Vue.use(HelperPlugin);

// import {vueTopprogress} from 'vue-top-progress';
import {DoubleBounce} from 'vue-loading-spinner';
// import moment from 'moment';

// import { CWLStatusCampanha } from '@/store/cwl_enums';

import { Orders } from '@/firebase/index';

export default {
  components: {
    // vueTopprogress,
    DoubleBounce,
  },
  mounted() {
    this.getFinancings();
  },
  data() {
    return {
      financings: [],
      totalizador: {
          total: 0
      },
      isLoading: true,
      modalidade: process.env.VUE_APP_CHECKOUT,
    };
  },
  computed: {
    status() {
      return this.$route.params.status ? this.$route.params.status : 'em-andamento'; // concluida-com-sucesso
    },
  },
  methods: {
    async getFinancings() {
      this.financings = [];
      const financings = await financingService.findAll();
      this.financings = financings.filter(e => e.status === this.status);
      this.financings.forEach(e => {
        this.calculosDaCampanha(e);
        this.totalizador.total = this.totalizador.total + e.totais.valor_total;
      });
      this.isLoading = false;
    },
    async calculosDaCampanha(financ) {
      const ordersFromFirebase = await Orders.where('financing_id', '==', financ.id).get();
      return this.$CWLCampanhaCalculosOrdens(financ, ordersFromFirebase);
    },
    formatDate(date) {
      return this.$CWLFormatDate(date);
    },
  }
};
</script>

<template>
  <div class="users-list">
    <div class="row">
      <div class="col-12">
          <div class="table-responsive" style="text-align: center;">
            <table class="table table-hover">
                <thead class="thead-light">
                    <tr>
                    <slot name="columns">
                        <th>Número</th>
                        <th>Sociedade empresária de pequeno porte</th>
                        <th style="text-align: center;">CNPJ</th>
                        <th style="text-align: center;">Data de encerramento da oferta</th>
                        <th style="text-align: center;">Valor captado</th>
                    </slot>
                    </tr>
                </thead>
                <tbody class="table">
                    <tr v-for="(financing, index) in financings" :key="index" style="cursor: default !important; height: 60px;">
                        <td>
                            {{ (index+1) }}
                        </td>
                        <td>
                            {{ financing.dados_complementares.empresa.nome }}
                        </td>
                        <td>
                            {{ financing.dados_complementares.empresa.documento.numero }}
                        </td>
                        <td>
                            {{ formatDate(financing.dates.end_at) }}
                        </td>
                        <td>
                            {{ financing.totais.valor_total_formatado }}
                        </td>
                    </tr>
                </tbody>
                <tfoot class="thead-light" v-if="financings.length">
                    <tr>
                        <th colspan="4" style="text-align: center;">Valor Total Captado na plataforma</th>
                        <th> {{ totalizador.total | currency }} </th>
                    </tr>
                </tfoot>
            </table>
            <double-bounce v-if="isLoading"></double-bounce>
          </div>
      </div>
    </div>
  </div>
</template>