import Firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfigAjxStage = {
  apiKey: "AIzaSyA0Oc92d25OFk13NG8j4Aabx0iVHdCFuu4",
  authDomain: "ecwl-ajx-stage.firebaseapp.com",
  projectId: "ecwl-ajx-stage",
  storageBucket: "ecwl-ajx-stage.appspot.com",
  messagingSenderId: "125153826482",
  appId: "1:125153826482:web:4f54d69718241a7a651bab",
  measurementId: "G-R917JJ5PSL"
};

const firebaseConfigAjxPRD = {
  apiKey: "AIzaSyBS0Wk_A2UaxEDEaqyRi0mi76tTkUZv7qw",
  authDomain: "ecwl-ajx.firebaseapp.com",
  projectId: "ecwl-ajx",
  storageBucket: "ecwl-ajx.appspot.com",
  messagingSenderId: "524400075760",
  appId: "1:524400075760:web:a332cb53661fba7632b335",
  measurementId: "G-NE8592T7V4"
};

var firebaseConfig = {};
if (process.env.VUE_APP_ENVIRONMENT == 'ajx-stage') {
  firebaseConfig = firebaseConfigAjxStage;
}
else if (process.env.VUE_APP_ENVIRONMENT == 'ajx-prd') {
  firebaseConfig = firebaseConfigAjxPRD;
}

export default Firebase.initializeApp(firebaseConfig);

const Database = Firebase.firestore();

const Storage = Firebase.storage();

const Logs = Database.collection('logs');
const Financing = Database.collection('financing');
const Investors = Database.collection('pessoas');
const Banks = Database.collection('banks');
const Segments = Database.collection('segment-interest');
const States = Database.collection('states');
const Values = Database.collection('values');
const InvestmentsProfile = Database.collection('investments-profile');
const Cards = Database.collection('cards');
const BankSlip = Database.collection('bank-slip');
const Orders = Database.collection('orders');
const Settings = Database.collection('settings');
const Pessoas = Database.collection('pessoas');

export {
  Logs,
  Financing,
  Investors,
  Storage,
  Banks,
  Segments,
  States,
  Values,
  InvestmentsProfile,
  Cards,
  BankSlip,
  Orders,
  Settings,
  Pessoas
};
