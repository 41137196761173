<script>
export default {
  name: 'payment-amount-form',
  props: {
    amount: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    error: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      value: {
        amount: this.amount || 0,
      },
    };
  },
};
</script>

<template>
  <div style="margin-top: 25px; margin-bottom: 25px">
    <div class="row col-md-12" style="margin-bottom: 25px;">
      <h3 slot="header" class="title">
        Informe o valor e confirme seus dados
      </h3>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="formValor">Qual valor você quer doar?*</label>
        <currency-input
          id="amount"
          v-model="value.amount"
          class="form-control"
          placeholder="Digite o valor que deseja doar"
          type="currency"
          required
          :auto-decimal-mode="true"
          :distraction-free="false"
          v-on:input="$emit('change', value)"
        />
      </div>
    </div>

    <div class="form-row col-md-12">
      <div style="color: red" v-if="error">{{ error }}</div>
    </div>
  </div>
</template>
