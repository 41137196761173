import firebase from 'firebase';

export default {
  dynamicFieldsRef() {
    return firebase.firestore().collection('dynamic-fields');
  },

  async getAll() {
    try {
      const snapshot = await this.dynamicFieldsRef().where("template", "==", "bacen").get();
      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map(value => ({
        id: value.id,
        ...value.data()
      }));
    } catch (error) {
      console.error('dynamicFields find all error', error);
    }
  },

  async getAllPublicWithKeys() {
    try {
      let all = await this.getAll();
      var allWithKeys = [];

      all.forEach((field) => {
        if ( field.is_public ) {
          allWithKeys[field.reference] = field;
        }
      });

      return allWithKeys;
    }
    catch (error) {
      console.error('getAllWithKeys find all error', error);
    }
  }
};
