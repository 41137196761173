<script>
export default {
  name: 'wizard-steps',
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<template>
  <div class="wizard-steps">
    <ul class="steps-list">
      <li
        :class="[
          { 'is-active': currentStep === step.id },
          { 'is-done': currentStep > step.id }
        ]"
        class="step"
        v-for="step in steps"
        :key="step.id"
      >
        <span class="steps-length"> de {{ steps.length }} </span>
        <div class="circle-step">
          <i class="fas fa-check" v-if="currentStep > step.id"></i>
          <span v-if="currentStep <= step.id">
            {{ step.id }}
          </span>
        </div>
        {{ step.name }}
      </li>
    </ul>
  </div>
</template>
