<script>
// import Navbar from '@/components/Navbar';
// import MobileMenu from '@/components/MobileMenu';

export default {
  components: {
    // Navbar,
    // MobileMenu
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    }
  },
  data() {
    return {
      openedMenu: false
    };
  },
  methods: {
    openMenuTrigger(value) {
      this.openedMenu = value;
    },
    closeMenu(value) {
      this.openedMenu = value;
    }
  }
};
</script>

<template>
  <div id="app">
    <!-- <MobileMenu @closeMenu="closeMenu" :openedMenu="openedMenu" />
    <Navbar @openedMenu="openMenuTrigger" /> -->
    <router-view />
  </div>
</template>

<style lang="scss">
@import './assets/styles/variables';
@import './assets/styles/bootstrap';
</style>
