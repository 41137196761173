<script>
import Vue from 'vue';

import VueClipboards from 'vue-clipboards';
import { VTooltip } from 'v-tooltip';

Vue.use(VueClipboards);
Vue.directive('tooltip', VTooltip);

export default {
  name: 'payment-completed',
  props: {
    title: {
      type: String,
    },
    error: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    paymentKind: {
      type: String,
      required: true,
    },
    barcode: {
      type: String,
      required: false,
    },
    barcodeUrl: {
      type: String,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      copiedTooltip: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    openBarcode() {
      window.open(this.barcodeUrl, '_blank');
    },
    showCopiedTooltip() {
      this.copiedTooltip = true;

      setTimeout(
        function() {
          this.copiedTooltip = false;
        }.bind(this),
        3000,
      );
    },
  },
};
</script>

<template>
  <div>
    <div class="card-body">
      <div class="alert alert-success" role="alert">
        <h5
          class="card-subtitle text-muted text-center"
          style="color: #fff !important; margin-top: 0px;"
        >
          OBRIGADO POR CONTRIBUIR COM ESTA CAUSA!
        </h5>
      </div>

      <div v-if="paymentKind === 'creditcard'">
        <div class="card-text">
          O pagamento foi confirmado e a sua contribuição já foi registrada.
        </div>
      </div>

      <div v-if="paymentKind === 'bankslip'">
        <div class="card-text">
          <div style="margin-bottom: 20px">
            Pague o boleto agora ou até a data do vencimento para confirmar sua
            contribuição.
          </div>

          <div v-if="barcode" style="margin-bottom: 40px">
            <hr style="border-top-color: #6c757d; opacity: 0.1;" />

            <h6
              class="card-subtitle text-muted text-center"
              style="margin-bottom: 10px; margin-top: 20px"
            >
              {{ barcode }}
            </h6>

            <p
              class="card-text text-muted text-center"
              style="cursor: pointer; color: #00bf9a !important; text-decoration: underline;"
              v-tooltip="{
                content: 'Copiado!',
                trigger: 'manual',
                show: copiedTooltip,
              }"
              v-clipboard="barcode"
              @success="showCopiedTooltip"
            >
              <b>Copiar código de barras</b>
            </p>
          </div>

          <div class="row" v-if="barcodeUrl">
            <hr style="border-top-color: #6c757d; opacity: 0.1;" />
            <div class="col-md-4"></div>
            <div class="col-md-4">
              <div
                class="alert alert-success"
                role="alert"
                style="cursor: pointer; background-color: #17a2b8;"
                @click="openBarcode"
              >
                <h5
                  class="card-subtitle text-muted text-center"
                  style="color: #fff !important; margin-top: 0px;"
                >
                  Visualizar Boleto
                </h5>
              </div>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>

      <div class="card-text" style="margin-top: 20px">
        Valor: <b style="color: #00bf9a;">{{ value }}</b>
      </div>

      <small class="form-text text-muted text-right" style="margin-top: 40px"
        ><i>Também enviamos estas informações para o seu e-mail.</i></small
      >
    </div>
  </div>
</template>
