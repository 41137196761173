<script>
import AddressForm from '@/components/address/AddressForm';
import CreditCardForm from '@/components/payment/CreditCardForm';

import { formatCurrency } from '@/formatter/NumberFormatter';

import moment from 'moment';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'payment-method',
  props: {
    title: {
      type: String,
    },
    initialData: {
      type: Object,
      required: false,
    },
  },
  components: {
    AddressForm,
    CreditCardForm,
  },
  data() {
    return {
      value: {
        paymentKind: 'creditcard',
        address: {},
        creditCard: {},
        ...this.initialData,
      },
    };
  },
  validations: {
    value: {
      address: {
        valid: (address, vm) => {
          if (vm.paymentKind !== 'bankslip') return true;
          return (
            required(address.publicPlace) &&
            required(address.number) &&
            required(address.district) &&
            required(address.city) &&
            required(address.state)
          );
        },
      },
      creditCard: {
        valid: (creditCard, vm) => {
          if (vm.paymentKind !== 'creditcard') return true;
          return (
            required(creditCard.cardNumber) &&
            required(creditCard.holder) &&
            required(creditCard.securityCode) &&
            required(creditCard.expirationDate)
          );
        },
        expirationDate: {
          valid: value => {
            if (value === '' || value === undefined) return false;
            var expireAt = moment(value, 'MM/YYYY').startOf('day');
            return expireAt
              .endOf('month')
              .isSameOrAfter(moment().endOf('month'));
          },
        },
      },
    },
  },
  computed: {
    amount() {
      return formatCurrency(this.value.amount);
    },
  },
  mounted() {
    this.amountFormatted = this.value.amount;
  },
  methods: {
    makePayment() {
      this.$emit('make-payment', this.value);
    },
    handleAddress(address) {
      this.value = {
        ...this.value,
        address,
      };
    },
    handleCreditCard(creditCard) {
      this.value = {
        ...this.value,
        creditCard,
      };
    },
    setPaymentKind(paymentKind) {
      this.value = {
        ...this.value,
        paymentKind,
      };
    },
  },
};
</script>

<template>
  <div>
    <div class="row col-md-12" style="margin-bottom: 25px;">
      <h3 slot="header" class="title">
        Forma de pagamento
      </h3>
    </div>

    <div class="form-row" v-if="value.amount">
      <div class="form-group col-md-12" style="margin-bottom: 25px;">
        <h6 slot="header" class="title">Valor de Contribuição: {{ amount }}</h6>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <div
          id="formFormaPagamento"
          class="btn-group col-md-12"
          role="group"
          aria-label="Forma de pagamento"
        >
          <button
            type="button"
            :class="
              value.paymentKind === 'bankslip'
                ? 'btn btn-outline-dark active'
                : 'btn btn-outline-dark'
            "
            @click="setPaymentKind('bankslip')"
          >
            Boleto
          </button>
          <button
            type="button"
            :class="
              value.paymentKind === 'creditcard'
                ? 'btn btn-outline-dark active'
                : 'btn btn-outline-dark'
            "
            @click="setPaymentKind('creditcard')"
          >
            Cartão de Crédito
          </button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="value.paymentKind === 'bankslip'">
        <address-form @change="handleAddress" :initialValue="value.address" />
        <div style="color: red" v-if="$v.value.address.$invalid">
          Informe todos os campos obrigatórios.
        </div>
      </div>
      <div v-if="value.paymentKind === 'creditcard'">
        <credit-card-form
          @change="handleCreditCard"
          :value="value.creditCard"
        />
        <div style="color: red" v-if="$v.value.creditCard.$invalid">
          Informe corretamente todos os campos.
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <button
            class="btn btn-success btn-lg col-md-12"
            style="background-color: #17a2b8; border-color: #17a2b8;"
            :disabled="
              (value.paymentKind === 'bankslip' && $v.value.address.$invalid) ||
                (value.paymentKind === 'creditcard' &&
                  $v.value.creditCard.$invalid)
            "
            v-on:click="makePayment"
          >
            FINALIZAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
