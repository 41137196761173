<script>
import attachmentService from '@/services/Attachments/attachmentService';
import pessoasService from '@/services/Pessoas/pessoasService';
import ordersService from '@/services/Orders/ordersService';
import financingService from '@/services/Financing/financingService';
import attachmentsService from '@/services/Attachments/attachmentService';
import settingsService from '@/services/Settings/settingsService';
import fiduciaService from '@/services/Fiducia/fiduciaService';
import VuePDFViewer from 'vue-instant-pdf-viewer';

import moment from 'moment';

import { Settings } from '@/firebase/index';

import { DoubleBounce } from 'vue-loading-spinner';
import ContratoModelo from '../components/contrato-modelo/ContratoModelo.vue';
import TermoUsoModelo from '../components/contrato-modelo/TermoUsoModelo.vue';

var libNumero = require('numero-por-extenso');

export default {
  name: 'terms-of-use',
  props: {
    financing: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'vue-pdf-viewer': VuePDFViewer,
    DoubleBounce,
    ContratoModelo,
    TermoUsoModelo
  },
  data() {
    return {
      attachments: [],
      page: 1,
      numPages: 0,
      currentPage: 0,
      pageCount: 0,
      scale: 'page-width',
      attachmentsUrl: [],
      visible: false,
      isLoading: true,
      termsAccepted: false,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      hasError: false,
      objetoContratoCCB: {
        ecwl_aporte: {},
        simulacao: {
          parcelas: {}
        },
        ecwl_oferta: {},
        credor: {},
        emitente: {},
        contrato: {}
      },

      order_admin: undefined,
      stopControl: false,
      zerar_contrato: false, 
      pdfTermosUsoLista: [],
    };
  },
  mounted() {
    if ( !this.is_admin ) {
      this.getAttachments();
    }
  },
  computed: {
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
    order() {
      if ( this.is_admin ) {
        return this.order_admin;
      }
      else {
        return this.$store.getters.getOrder;
      }
    },
    is_admin() {
      // console.log(this.$route.params);
      return this.$route.params.admin == "13579";
    },
  },
  methods: {
    async generatePdfTermoUso() {
      this.pdfTermosUsoLista = [];
      // let ordersSnapshot = await ordersService.getPaidOrders();
      let ordersSnapshotTotal = await ordersService.getPaidAndWithoutTermoUsoPdfOrdersSemLimite();
      let ordersSnapshot = await ordersService.getPaidAndWithoutTermoUsoPdfOrders();
      let that = this;

      console.log("TOTAL DE ORDENS PAGAS E SEM TERMOS: " + ordersSnapshotTotal.size);
      console.log("TOTAL DE ORDENS P/ GERAR OS TERMOS: " + ordersSnapshot.size);
      // await ordersSnapshot.docs.map(async snapshot => {
      //   let order = snapshot.data();
      //   order.status_w_has_termo_uso = "Pago_Nao";
      //   await ordersService.update(order.id, order);
      //   i = i + 1;
      //   console.log("ORDENS ATUALIZADAS: " + i);
      // });
      // return;
      await ordersSnapshot.docs.map(async snapshot => {
        let order = snapshot.data();
        
        if ( order.pessoa && order.pessoa.id ) {
          /**
           * Pessoa
           */
          let pessoa_id = order.pessoa.id;
          const pessoaFromFirebase = await pessoasService.getPessoa(pessoa_id);
          const pessoa = pessoaFromFirebase.data();

          /**
           * Oferta
           */
          // let financing_id = order.financing_id;
          // const financingFromFirebase = await financingService.findById(financing_id);
          
          /**
           * Order
           */

          order.user = order.pessoa;
          if ( order.item_bkp ) {
            order.item = order.item_bkp;
          }

          let dataToUse = moment(order.order_date, 'YYYY/MM/DD H:m');
          let data = dataToUse.format('DD/MM/Y');
          let hora = dataToUse.format('H:mm');

          var ip = order.investidor_ip;
          if ( !ip || ip == "" ) {
            ip = "127.0.0.1";
          }
          var hash_assinatura = order.aceite_termos_hash;
          if ( !hash_assinatura || hash_assinatura == "" ) {
            hash_assinatura = fiduciaService.generateHashAssinatura();
          }

          let custom_object = {
            investidor_nome: pessoa.nome,
            investidor_cpf: pessoa.documento.numero,
            data: data,
            hora: hora,
            investidor_email: pessoa.email.email,
            investidor_ip: ip,
            hash_assinatura: hash_assinatura,
            order: order
          };
          console.log(custom_object);
          that.pdfTermosUsoLista.push(custom_object);
        }
      });

      // setTimeout( function () {
      //   that.generatePdfTermoUso();
      // }, 20000);
    },
    async uploadTermoUso_ADMIN(objeto) {
      var pdfString = objeto.pdfString;
      var order = objeto.order;
      // console.log(pdfString);
      if ( !pdfString ) {
        return;
      }

      var storageRef = "TermosUso/";
      var fileName = "";
      if ( order.ccb && order.ccb.simulacao && order.ccb.simulacao.numero_ccb ) {
        fileName = order.ccb.simulacao.numero_ccb + "_TermoUso.pdf";
      }
      else {
        fileName = "sem_numero_CCB_" + fiduciaService.generateHash() + "_TermoUso.pdf";
      }
      // return;

      try {
        let response = await attachmentsService.addNewAttachment(pdfString, fileName, storageRef);

        order.status_w_has_termo_uso = "Pago_Sim";
        await ordersService.update(order.id, order);
        console.log(response);
      }
      catch (error) {
        console.log(error);
      }
    },
    async getAttachments() {
      const attachments = await attachmentService.getTermsAttachments();
      const attachmentItems = await attachments.items;

      const { pessoa_id } = this.loggedPessoa;
      const pessoaFromFirebase = await pessoasService.getPessoa(pessoa_id);
      const pessoa = pessoaFromFirebase.data();
      
      const attachmentsArray = [];

      await attachmentItems.map(async item => {
        if ( item.name.toLowerCase() == 'Autodeclaração - Investidor Qualificado - Anexo 4-A'.toLowerCase() ) {
          if ( pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_financeiros > 1000000 ) {
            attachmentsArray.push({ item: item, url: await this.getURL(item.name), tipo: 'pdf' });
          }
        }
        else if ( item.name.toLowerCase() == 'Autodeclaração - Anexo 4-B'.toLowerCase() ) {
          if ( pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.renda_bruta_anual > 100000 && pessoa.dados_complementares.perfil.investimentos_financeiros <= 1000000 ) {
            attachmentsArray.push({ item: item, url: await this.getURL(item.name), tipo: 'pdf' });
          }
        }
        else if ( item.name.toLowerCase() == 'Autodeclaração - Anexo 4-C'.toLowerCase() ) {
          if ( pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.renda_bruta_anual < 100000 && pessoa.dados_complementares.perfil.investimentos_financeiros <= 1000000 ) {
            attachmentsArray.push({ item: item, url: await this.getURL(item.name), tipo: 'pdf' });
          }
        }
        else {
          attachmentsArray.push({ item: item, url: await this.getURL(item.name), tipo: 'pdf' });
        }
      });

      
      this.attachments = attachmentsArray;
      
      await this.getAutoDeclaracao(pessoa);
      // this.isLoading = false;
      await this.getContratoOferta(pessoa);
      await this.getTermosRisco(pessoa);
    },
    async regerarContrato_ADMIN() {
      /**
       * ATENÇÃO!!!
       * Esta função foi criada em Maio/2022, pois tivemos um bug que houveram 4 aportes com o contrato em branco.
       * Então criamos esta função para regerar estes contratos
       * 
       * Em 18/07/2022 identificamos que houveram mais contratos em branco. Então alterei essa função para buscar todos os anexos com tamanho inferior a 1MB para reprocessar.
       */
      let that = this;
      const attachments = await attachmentService.getTermsAttachments("ContratosCCB");
      const attachmentItems = await attachments.items;
      this.zerar_contrato = true;

      var whiteDocsFound = 0;
      var whiteDocsRegenerated = 0;
      await attachmentItems.map(async item => {
        let metadata = await item.getMetadata();
        if ( metadata.size < 3000000 ) {
          whiteDocsFound = whiteDocsFound + 1;
          let name = metadata.name;
          let numero_ccb = name.replace("_ContratoCCB.pdf", "");
          let ordersSnapshot = await ordersService.getOrderByNumeroCCB(numero_ccb);
          await ordersSnapshot.docs.map(async snapshot => {
            let order = snapshot.data();
            
            if ( order.pessoa && order.pessoa.id ) {
              if ( !that.stopControl ) {
                that.stopControl = true;
                /**
                 * Pessoa
                 */
                let pessoa_id = order.pessoa.id;
                const pessoaFromFirebase = await pessoasService.getPessoa(pessoa_id);
                const pessoa = pessoaFromFirebase.data();

                /**
                 * Oferta
                 */
                let financing_id = order.financing_id;
                const financingFromFirebase = await financingService.findById(financing_id);
                const financing = financingFromFirebase;
                // console.log(financing);
                console.log(financing.name);
                console.log(pessoa.nome);
                // console.log(1);

                /**
                 * Order
                 */

                order.user = order.pessoa;
                order.item_bkp = order.item;
                order.item = financing;
                order.item.order = {
                  totalValue: order.item_bkp.value
                }

                that.order_admin = order;

                let data = moment(order.order_date, 'YYYY/MM/DD H:m');

                var ip = order.investidor_ip;
                if ( !ip || ip == "" ) {
                  ip = "127.0.0.1";
                }

                let custom_object = {
                  pessoa: pessoa,
                  financing: financing,
                  data: data,
                  ip: ip
                };
                await that.makeObjetoContratoCCB(pessoa, custom_object);
              }
              whiteDocsRegenerated = whiteDocsRegenerated + 1;
            }
          });
        }
        // console.log("Contratos em branco encontrados: " + whiteDocsFound);
        console.log("Contratos em branco que podem ser regerados: " + whiteDocsRegenerated);
      });
    },
    async getAutoDeclaracao(pessoa) {
      const settingsFromFirebase = await Settings.get();

      var settings = settingsFromFirebase.docs[0].data();
      settings.id = settingsFromFirebase.docs[0].id;

      // attachmentsArray.push({ item: item, url: await this.getURL(item.name), tipo: 'pdf' });

      const settings_termo4A = settings.termos_4a;
      const settings_termo4B = settings.termos_4b;
      const settings_termo4C = settings.termos_4c;

      const hoje = moment().format('DD/MM/YYYY');
      const razaoSocial = this.financing.dados_complementares.empresa.nome;
      
      var outrasPlataformas = 0;
      var nestaPlataforma = 0;
      if ( pessoa.dados_complementares && pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_outras_plataformas ) {
        outrasPlataformas = pessoa.dados_complementares.perfil.investimentos_outras_plataformas;
        nestaPlataforma = pessoa.dados_complementares.perfil.investimentos_nesta_plataforma;
      }
      
      const montantePreFormatado = parseFloat(outrasPlataformas + nestaPlataforma).toFixed(2).replace('.', ',');
      const montante = 'R$ ' + montantePreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');;
      
      var termo4A = settings_termo4A.replace('#data', hoje)
                                    .replace('#local', pessoa.endereco.cidade)
                                    .replace('#nomeInvestidor', pessoa.nome)
                                    .replace('#cpfInvestidor', pessoa.documento.numero);

      var termo4B = settings_termo4B.replace('#data', hoje)
                                    .replace('#local', pessoa.endereco.cidade)
                                    .replace('#nomeInvestidor', pessoa.nome)
                                    .replace('#cpfInvestidor', pessoa.documento.numero)
                                    .replace('#razaoSocialEmissor', razaoSocial)
                                    .replace('#montante', montante);

      var termo4C = settings_termo4C.replace('#data', hoje)
                                    .replace('#local', pessoa.endereco.cidade)
                                    .replace('#nomeInvestidor', pessoa.nome)
                                    .replace('#cpfInvestidor', pessoa.documento.numero)
                                    .replace('#razaoSocialEmissor', razaoSocial)
                                    .replace('#montante', montante);

      if ( this.submodalidade == '588' ) {

        if ( pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_financeiros > 1000000 ) {
          this.attachments.push({ item: {content: termo4A, name: 'Autodeclaração - Anexo 4-A'}, url: '', tipo: 'editor', parseRef: 'parsedContentTermoAnexo' });
        }
        else if ( pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.renda_bruta_anual > 100000 && pessoa.dados_complementares.perfil.investimentos_financeiros <= 1000000 ) {
          this.attachments.push({ item: {content: termo4B, name: 'Autodeclaração - Anexo 4-B'}, url: '', tipo: 'editor', parseRef: 'parsedContentTermoAnexo' });
        }
        else if ( pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.renda_bruta_anual <= 100000 && pessoa.dados_complementares.perfil.investimentos_financeiros <= 1000000 ) {
          this.attachments.push({ item: {content: termo4C, name: 'Autodeclaração - Anexo 4-C'}, url: '', tipo: 'editor', parseRef: 'parsedContentTermoAnexo' });
        }
      }
    },
    async makeObjetoContratoCCB(pessoa, custom_object = undefined) {
      moment.locale('pt_br');
      const publicSettings = await settingsService.getPublicSettings();
      const privateSettings = await settingsService.getPrivateSettings();

      var dataAporteParaTrabalhar = moment();
      if ( custom_object ) {
        dataAporteParaTrabalhar = custom_object.data;
      }

      const that = this;
      const valorAporte = that.order.item.order.totalValue;

      let dataAporteDia = dataAporteParaTrabalhar.format('DD');
      let dataAporteMes = dataAporteParaTrabalhar.format('MMMM');
      let dataAporteAno = dataAporteParaTrabalhar.format('YYYY');
      let dataAporte = dataAporteDia + " de " + dataAporteMes.charAt(0).toUpperCase() + dataAporteMes.substring(1) + " de " + dataAporteAno;

      var investidorEndereco = that.order.user.endereco.rua + ", " + that.order.user.endereco.numero;
      if ( that.order.user.endereco.complemento && that.order.user.endereco.complemento != "" ) {
        investidorEndereco = investidorEndereco + ", " + that.order.user.endereco.complemento;
      }
      investidorEndereco = investidorEndereco + " - " + that.order.user.endereco.bairro;

      /**
       * Campos dinâmicos
       */
      var ofertaTaxaJuros = "";
      if ( that.order.item.fields && that.order.item.fields['taxa_juros'] ) {
        ofertaTaxaJuros = that.order.item.fields['taxa_juros'].value;
      }
      var ofertaJurosMora = "";
      if ( that.order.item.fields && that.order.item.fields['juros_mora'] ) {
        ofertaJurosMora = that.order.item.fields['juros_mora'].value;
      }
      var ofertaMultaPorInadimplento = "";
      if ( that.order.item.fields && that.order.item.fields['multa_por_inadimplento'] ) {
        ofertaMultaPorInadimplento = that.order.item.fields['multa_por_inadimplento'].value;
      }
      var ofertaPrimeiroVencimento = "";
      if ( that.order.item.fields && that.order.item.fields['primeiro_vencimento'] ) {
        ofertaPrimeiroVencimento = that.order.item.fields['primeiro_vencimento'].value;
      }
      var ofertaTipoRegimeEmitente = "PRÉ-FIXADO";
      if ( that.order.item.fields && that.order.item.fields['tipo_regime_emitente'] ) {
        ofertaTipoRegimeEmitente = that.order.item.fields['tipo_regime_emitente'].value;
      }
      var ofertaDataEncerramento = "";
      if ( that.order.item.fields && that.order.item.fields['Encerramento da Oferta'] ) {
        ofertaDataEncerramento = that.order.item.fields['Encerramento da Oferta'].value;
      }

      /**
       * Empresa
       */
      var ofertaEmpresaRua = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa && that.order.item.dados_complementares.empresa.endereco ) {
        ofertaEmpresaRua = that.order.item.dados_complementares.empresa.endereco.rua + ", " + that.order.item.dados_complementares.empresa.endereco.numero;
        if ( that.order.item.dados_complementares.empresa.endereco.complemento && that.order.item.dados_complementares.empresa.endereco.complemento != "" ) {
          ofertaEmpresaRua = ofertaEmpresaRua + ", " + that.order.item.dados_complementares.empresa.endereco.complemento;
        }
        ofertaEmpresaRua = ofertaEmpresaRua + " - " + that.order.item.dados_complementares.empresa.endereco.bairro;
      }
      var ofertaEmpresaCEP = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa && that.order.item.dados_complementares.empresa.endereco ) {
        ofertaEmpresaCEP = that.order.item.dados_complementares.empresa.endereco.cep;
      }
      var ofertaEmpresaCidade = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa && that.order.item.dados_complementares.empresa.endereco ) {
        ofertaEmpresaCidade = that.order.item.dados_complementares.empresa.endereco.cidade;
      }
      var ofertaEmpresaUF = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa && that.order.item.dados_complementares.empresa.endereco ) {
        ofertaEmpresaUF = that.order.item.dados_complementares.empresa.endereco.estado;
      }
      var ofertaEmpresaRazaoSocial = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa ) {
        ofertaEmpresaRazaoSocial = that.order.item.dados_complementares.empresa.nome;
      }
      var ofertaEmpresaCNPJ = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa && that.order.item.dados_complementares.empresa.documento ) {
        ofertaEmpresaCNPJ = that.order.item.dados_complementares.empresa.documento.numero;
      }
      var ofertaEmpresaEmail = "";
      if ( that.order.item.dados_complementares && that.order.item.dados_complementares.empresa && that.order.item.dados_complementares.empresa.email ) {
        ofertaEmpresaEmail = that.order.item.dados_complementares.empresa.email.email;
      }

      /**
       * Oferta
       */
      var ofertaCidade = "";
      if ( that.order.item.cidade ) {
        ofertaCidade = that.order.item.cidade;
      }
      var ofertaUF = "";
      if ( that.order.item.estado ) {
        ofertaUF = that.order.item.estado.uf;
      }

      /**
       * ECWL_APORTE
       */
      if ( custom_object ) {
        that.objetoContratoCCB.ecwl_aporte.investidor_ip = custom_object.ip;
      }
      else {
        try {
          // await fetch('https://api.ipify.org?format=json')
          //   .then(x => x.json())
          //   .then(({ ip }) => {
          //     that.objetoContratoCCB.ecwl_aporte.investidor_ip = ip;
          //   });

          that.objetoContratoCCB.ecwl_aporte.investidor_ip = '127.0.0.1';
        }
        catch ( e ) {
          that.hasError = true;
          that.$toasted.show('Ocorreu um erro na simulação. Por favor, entre em contato com a plataforma.', {
            icon: 'times',
            className: 'error',
          });
        }
      }

      
      const valorPorExtenso = libNumero.porExtenso(valorAporte, libNumero.estilo.monetario).toUpperCase()
      
      that.objetoContratoCCB.ecwl_aporte.valor_extenso = valorPorExtenso;
      that.objetoContratoCCB.ecwl_aporte.numero_ccb = that.order.ccb.simulacao.numero_ccb;
      that.objetoContratoCCB.ecwl_aporte.valor = valorAporte;
      that.objetoContratoCCB.ecwl_aporte.data = dataAporteParaTrabalhar.format('DD/MM/Y');
      that.objetoContratoCCB.ecwl_aporte.data_extenso = dataAporte;
      that.objetoContratoCCB.ecwl_aporte.hora = dataAporteParaTrabalhar.format('H:mm');
      that.objetoContratoCCB.ecwl_aporte.investidor_nome = that.order.user.nome.toUpperCase();
      that.objetoContratoCCB.ecwl_aporte.investidor_email = that.order.user.email.email;
      that.objetoContratoCCB.ecwl_aporte.investidor_cpf = that.order.user.documento.numero;
      that.objetoContratoCCB.ecwl_aporte.investidor_endereco = investidorEndereco.toUpperCase();
      that.objetoContratoCCB.ecwl_aporte.investidor_endereco_cidade = that.order.user.endereco.cidade.toUpperCase();
      that.objetoContratoCCB.ecwl_aporte.investidor_endereco_cep = that.order.user.endereco.cep;
      that.objetoContratoCCB.ecwl_aporte.investidor_endereco_uf = (that.order.user.endereco.estado.uf || that.order.user.endereco.estado).toUpperCase();
      that.objetoContratoCCB.ecwl_aporte.hash_assinatura = fiduciaService.generateHashAssinatura();
      
      /**
       * SIMULACAO
       */
      that.objetoContratoCCB.simulacao.valor_liberado_nf = that.order.ccb.simulacao.response.valor_liberado_NF;
      that.objetoContratoCCB.simulacao.taxa_juros_anual = that.order.ccb.simulacao.response.taxa_juros_anual.toString() + "%";
      that.objetoContratoCCB.simulacao.cet_nf = that.order.ccb.simulacao.response.CET_NF;
      that.objetoContratoCCB.simulacao.iof_nf = that.order.ccb.simulacao.response.IOF_NF;
      that.objetoContratoCCB.simulacao.parcelas.numero_parcelas = that.order.ccb.simulacao.payload.parcelas + " Parcela(s)";
      that.objetoContratoCCB.simulacao.parcelas.data_final_vencimento = ofertaPrimeiroVencimento;
      that.objetoContratoCCB.simulacao.parcelas.lista = [];

      var dataPagamentoMoment = moment(ofertaPrimeiroVencimento);
      await that.order.ccb.simulacao.response.NF_table.map(async nf_parcela => {
        if ( nf_parcela.numeroPrestacao > 1 ) {
          dataPagamentoMoment = moment(ofertaDataEncerramento).add(nf_parcela.diasCorridos, 'days');
        }
        let parcela = {
          numero_prestacao: nf_parcela.numeroPrestacao,
          data_pagamento: dataPagamentoMoment.format('DD/MM/YYYY'),
          saldo_devedor: nf_parcela.amortizacao,
          juros: nf_parcela.juros,
          valor_parcelas: nf_parcela.valorParcelas
        }
        that.objetoContratoCCB.simulacao.parcelas.lista.push(parcela);
        that.objetoContratoCCB.simulacao.parcelas.data_final_vencimento = dataPagamentoMoment.format('DD/MM/YYYY');
      });

      /**
       * ECWL_OFERTA
       */
      that.objetoContratoCCB.ecwl_oferta.taxa_juros = ofertaTaxaJuros + "%";
      that.objetoContratoCCB.ecwl_oferta.juros_mora = ofertaJurosMora + "%";
      that.objetoContratoCCB.ecwl_oferta.multa_por_inadimplento = ofertaMultaPorInadimplento + "%";
      that.objetoContratoCCB.ecwl_oferta.tipo_regime_emitente = ofertaTipoRegimeEmitente;
      that.objetoContratoCCB.ecwl_oferta.primeiro_vencimento = moment(ofertaPrimeiroVencimento).format("DD/MM/YY");
      that.objetoContratoCCB.ecwl_oferta.empresa_rua = ofertaEmpresaRua.toUpperCase();
      that.objetoContratoCCB.ecwl_oferta.empresa_cep = ofertaEmpresaCEP;
      that.objetoContratoCCB.ecwl_oferta.empresa_cidade = ofertaEmpresaCidade.toUpperCase();
      that.objetoContratoCCB.ecwl_oferta.empresa_uf = ofertaEmpresaUF.toUpperCase();
      that.objetoContratoCCB.ecwl_oferta.cidade = ofertaCidade.toUpperCase();
      that.objetoContratoCCB.ecwl_oferta.uf = ofertaUF.toUpperCase();
      that.objetoContratoCCB.ecwl_oferta.empresa_razao_social = ofertaEmpresaRazaoSocial.toUpperCase();
      that.objetoContratoCCB.ecwl_oferta.empresa_cnpj = ofertaEmpresaCNPJ;
      that.objetoContratoCCB.ecwl_oferta.empresa_email = ofertaEmpresaEmail;
      that.objetoContratoCCB.ecwl_oferta.data_encerramento = moment(ofertaDataEncerramento).format("DD/MM/YYYY");

      that.objetoContratoCCB.ecwl_oferta.valor_tarifa = this.getValorTarifa();
      
      /**
       * Início - Criar estes campos em algum lugar
       */

      /**
       * CREDOR
       */

      if ( privateSettings.is_bancarizador_manual ) {
        that.objetoContratoCCB.credor.razao_social = privateSettings.bancarizador_razao_social;
        that.objetoContratoCCB.credor.cnpj = privateSettings.bancarizador_cnpj;
        that.objetoContratoCCB.credor.endereco = privateSettings.bancarizador_endereco;
        that.objetoContratoCCB.credor.cep = privateSettings.bancarizador_cep;
        that.objetoContratoCCB.credor.cidade = privateSettings.bancarizador_cidade;
        that.objetoContratoCCB.credor.uf = privateSettings.bancarizador_uf;
        that.objetoContratoCCB.credor.natureza = privateSettings.bancarizador_natureza;
        that.objetoContratoCCB.credor.praca_pagamento = privateSettings.bancarizador_praca_pagamento;
        that.objetoContratoCCB.credor.email = privateSettings.bancarizador_email;
      }
      else {
        that.objetoContratoCCB.credor.razao_social = "FIDUCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMP.";
        that.objetoContratoCCB.credor.cnpj = "04.307.598/0001-17";
        that.objetoContratoCCB.credor.endereco = "PAULO ANTONIO DO NASCIMENTO,145 SALA 105 E 104";
        that.objetoContratoCCB.credor.cep = "18047- 400";
        that.objetoContratoCCB.credor.cidade = "SOROCABA";
        that.objetoContratoCCB.credor.uf = "SP";
        that.objetoContratoCCB.credor.natureza = "Abertura de Empréstimo - Crédito Fixo.";
        that.objetoContratoCCB.credor.praca_pagamento = "SOROCABA (SP)";
        that.objetoContratoCCB.credor.email = "assinatura@fiduciascm.com.br";
      }

      /**
       * CONTRATO
       */
      var contratoCCB = '';
      var contratoCCBEndosso = '';
      var contratoCCBEndossoComplemento = '';
      if ( publicSettings.terms ) {
        
        var contratoCCBFromSettings = '';
        var contratoCCBEndossoFromSettings = '';
        var contratoCCBEndossoComplementoFromSettings = '';
        if ( publicSettings.terms.contrato_ccb ) {
          contratoCCBFromSettings = publicSettings.terms.contrato_ccb;
        }
        if ( publicSettings.terms.contrato_ccb_endosso ) {
          contratoCCBEndossoFromSettings = publicSettings.terms.contrato_ccb_endosso;
        }
        if ( publicSettings.terms.contrato_ccb_endosso_complemento ) {
          contratoCCBEndossoComplementoFromSettings = publicSettings.terms.contrato_ccb_endosso_complemento;
        }

        var outrasPlataformas = 0;
        var nestaPlataforma = 0;
        if ( pessoa.dados_complementares && pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_outras_plataformas ) {
          outrasPlataformas = pessoa.dados_complementares.perfil.investimentos_outras_plataformas;
          nestaPlataforma = pessoa.dados_complementares.perfil.investimentos_nesta_plataforma;
        }

        const montantePreFormatado = parseFloat(outrasPlataformas + nestaPlataforma).toFixed(2).replace('.', ',');
        const montante = 'R$ ' + montantePreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        contratoCCB = contratoCCBFromSettings.replace('#data', that.objetoContratoCCB.ecwl_aporte.data)
                          .replace('#local', that.objetoContratoCCB.ecwl_aporte.investidor_endereco_cidade)
                          .replace('#nomeInvestidor', that.objetoContratoCCB.ecwl_aporte.investidor_nome)
                          .replace('#cpfInvestidor', that.objetoContratoCCB.ecwl_aporte.investidor_cpf)
                          .replace('#razaoSocialEmissor', that.objetoContratoCCB.ecwl_oferta.empresa_razao_social)
                          .replace('#montante', montante)
                          .replace('#bancarizadorRazaoSocial', that.objetoContratoCCB.credor.razao_social)
                          .replace('#bancarizadorCidade', that.objetoContratoCCB.credor.cidade)
                          .replace('#bancarizadorCNPJ', that.objetoContratoCCB.credor.cnpj);

        contratoCCBEndosso = contratoCCBEndossoFromSettings.replace('#data', that.objetoContratoCCB.ecwl_aporte.data)
                          .replace('#local', that.objetoContratoCCB.ecwl_aporte.investidor_endereco_cidade)
                          .replace('#nomeInvestidor', that.objetoContratoCCB.ecwl_aporte.investidor_nome)
                          .replace('#cpfInvestidor', that.objetoContratoCCB.ecwl_aporte.investidor_cpf)
                          .replace('#razaoSocialEmissor', that.objetoContratoCCB.ecwl_oferta.empresa_razao_social)
                          .replace('#montante', montante);

        contratoCCBEndossoComplemento = contratoCCBEndossoComplementoFromSettings.replace('#data', that.objetoContratoCCB.ecwl_aporte.data)
                          .replace('#local', that.objetoContratoCCB.ecwl_aporte.investidor_endereco_cidade)
                          .replace('#nomeInvestidor', that.objetoContratoCCB.ecwl_aporte.investidor_nome)
                          .replace('#cpfInvestidor', that.objetoContratoCCB.ecwl_aporte.investidor_cpf)
                          .replace('#razaoSocialEmissor', that.objetoContratoCCB.ecwl_oferta.empresa_razao_social)
                          .replace('#montante', montante);
      }
      that.objetoContratoCCB.contrato.contrato_ccb = contratoCCB;
      that.objetoContratoCCB.contrato.anexo_contrato_ccb_avalista = contratoCCBEndossoComplemento;
      that.objetoContratoCCB.contrato.anexo_contrato_ccb = contratoCCBEndosso;

      // console.log(that.objetoContratoCCB);
    },
    getPrimeiroVencimentoSimulacao() {
      var primeiroVencimento = "";
      var primeiroVencimentoMoment = {};
      if ( this.financing.fields['primeiro_vencimento'] ) {
        primeiroVencimento = this.financing.fields['primeiro_vencimento'].value;
        primeiroVencimentoMoment = moment(primeiroVencimento);
      }
      var dataEncerramento = "";
      var dataEncerramentoMoment = {};
      if ( this.financing.fields['Encerramento da Oferta'] ) {
        dataEncerramento = this.financing.fields['Encerramento da Oferta'].value;
        dataEncerramentoMoment = moment(dataEncerramento);
      }

      let diferencaPrimeiroVencimentoDataEncerramento = primeiroVencimentoMoment.diff(dataEncerramentoMoment, "days");
      let primeiroVencimentoSimulacaoMoment = moment().add(diferencaPrimeiroVencimentoDataEncerramento, "days");

      return primeiroVencimentoSimulacaoMoment.format('YYYY-MM-DD');
    },
    getValorTarifa() {
      let that = this;

      const valorAporte = that.order.item.order.totalValue;

      var ofertaTacMinimo = "";
      if ( that.order.item.fields && that.order.item.fields['tac_minimo'] ) {
        ofertaTacMinimo = that.order.item.fields['tac_minimo'].value;
      }
      var ofertaTacPercentual = "";
      if ( that.order.item.fields && that.order.item.fields['tac_percentual'] ) {
        ofertaTacPercentual = that.order.item.fields['tac_percentual'].value;
      }
      var ofertaTacFixo = "";
      if ( that.order.item.fields && that.order.item.fields['tac_fixo'] ) {
        ofertaTacFixo = that.order.item.fields['tac_fixo'].value;
      }

      var valorTarifa = ofertaTacMinimo;
      var calculo1 = (Number(ofertaTacPercentual) / 100) * valorAporte;
      var calculo2 = calculo1 + ofertaTacFixo;
      if ( calculo2 > valorTarifa ) {
        valorTarifa = calculo2;
      }

      return valorTarifa;
    },
    getDadosBancarizadorManual() {
      var valor = this.order.item.order.totalValue;

      var valor_liberado_NF = 0;
      var taxa_juros_anual = 0; // campos dinamicos
      var CET_NF = 0; // campos dinamicos
      var IOF_NF = 0; // campos dinamicos
      var juros = 0; // campos dinamicos
      var valorParcelas = 0;

      if ( this.order.item.fields && this.order.item.fields['bancarizador_manual_taxa_juros_anual'] ) {
        
        // var r = ((1+(2.4/100))**(12)-1)*100;
        // console.log(parseFloat(r).toFixed(2));

        taxa_juros_anual = this.order.item.fields['bancarizador_manual_taxa_juros_anual'].value;
        juros = valor * taxa_juros_anual;

        valorParcelas = valor + juros;
      }
      if ( this.order.item.fields && this.order.item.fields['bancarizador_manual_CET_NF'] ) {
        CET_NF = this.order.item.fields['bancarizador_manual_CET_NF'].value;
      }
      if ( this.order.item.fields && this.order.item.fields['bancarizador_manual_IOF_NF'] ) {
        let iof_percent = this.order.item.fields['bancarizador_manual_IOF_NF'].value;
        IOF_NF = valor * iof_percent;
        
        valor_liberado_NF = valor - IOF_NF;
      }
      // if ( this.order.item.fields && this.order.item.fields['bancarizador_manual_juros'] ) {
      //   juros = this.order.item.fields['bancarizador_manual_juros'].value;
      // }

      var NF_table_item = {
        numeroPrestacao: 1,
        amortizacao: valor,
        juros: juros,
        valorParcelas: valorParcelas
      }

      var NF_table = [];
      NF_table.push(NF_table_item);

      return {
        valor_liberado_NF: valor_liberado_NF,
        taxa_juros_anual: taxa_juros_anual,
        CET_NF: CET_NF,
        IOF_NF: IOF_NF,
        NF_table: NF_table
      };
    },
    Rate(periods, payment, present, future = 0, type = 0, guess = 1) {
      /*
        Essa função cálcula o custo efetivo total de uma oferta, com base na função RATE/TAXA do MSExcel.
      */
        guess = (guess === undefined) ? 0.01 : guess;
        future = (future === undefined) ? 0 : future;
        type = (type === undefined) ? 0 : type;
      
        // Set maximum epsilon for end of iteration
        var epsMax = 1e-10;
      
        // Set maximum number of iterations
        var iterMax = 10;
      
        // Implement Newton's method
        var y, y0, y1, x0, x1 = 0,
          f = 0,
          i = 0;
        var rate = guess;
        if (Math.abs(rate) < epsMax) {
          y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
        } else {
          f = Math.exp(periods * Math.log(1 + rate));
          y = present * f + payment * (1 / rate + type) * (f - 1) + future;
        }
        y0 = present + payment * periods + future;
        y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
        i = x0 = 0;
        x1 = rate;
        while ((Math.abs(y0 - y1) > epsMax) && (i < iterMax)) {
          rate = (y1 * x0 - y0 * x1) / (y1 - y0);
          x0 = x1;
          x1 = rate;
            if (Math.abs(rate) < epsMax) {
              y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
            } else {
              f = Math.exp(periods * Math.log(1 + rate));
              y = present * f + payment * (1 / rate + type) * (f - 1) + future;
            }
          y0 = y1;
          y1 = y;
          ++i;
        }
        return rate;
    },
    getSimulacaoLocal(objeto) {

      var valorOperacao = objeto.valor_liberado;

      var valor_liberado_NF = 0;
      var taxa_juros_anual = 0; // campos dinamicos
      var CET_NF = 0; // campos dinamicos
      var IOF_NF = 0; // campos dinamicos
      var juros = 0; // campos dinamicos
      var valorParcelas = 0;

      //Calculo de juros
      taxa_juros_anual = ((1+(objeto.taxa_juros/100))**(12)-1)*100; //Para exibição em relatório
      
      juros = valorOperacao * ((((1+(objeto.taxa_juros/100))**(objeto.prazoOperacao/30)-1)*100/100)); //Cálcula os juros sobre o valor aportado considerando todo o período de rentabilidade 
      
      valorParcelas = valorOperacao + juros;
      
      //Calculo de IOF
      const iof_percent = 0.0041;
      const iof_add_percent = 0.0038;
      IOF_NF = (valorOperacao * ((iof_percent * objeto.prazoOperacao)/100)) + (valorOperacao * iof_add_percent);
      
        //Limitar a 3%
        if ( IOF_NF > (valorOperacao * 0.03)) {
          IOF_NF = valorParcelas * 0.03;
        }
      
      valor_liberado_NF = valorOperacao - IOF_NF;
      
      //Calculo CET
      CET_NF = this.Rate(1, -Math.abs(valorOperacao + juros),valor_liberado_NF) * 100;
      
      var NF_table_item = {
        numeroPrestacao: 1,
        amortizacao: valorOperacao,
        juros: juros,
        valorParcelas: valorParcelas
      }
      
      var NF_table = [];
      NF_table.push(NF_table_item);
      
      // console.log('Simulação Local', {
      //   valor_liberado_NF: valor_liberado_NF,
      //   taxa_juros_anual: taxa_juros_anual.toFixed(2),
      //   CET_NF: CET_NF.toFixed(2),
      //   IOF_NF: IOF_NF.toFixed(2),
      //   NF_table: NF_table
      // });
      return {
        valor_liberado_NF: valor_liberado_NF,
        taxa_juros_anual: taxa_juros_anual.toFixed(2),
        CET_NF: CET_NF.toFixed(2),
        IOF_NF: IOF_NF.toFixed(2),
        NF_table: NF_table
      };
    },
    async getContratoOferta(pessoa) {
      /**
       * Contratos
       */
      const publicSettings = await settingsService.getPublicSettings();
      const privateSettings = await settingsService.getPrivateSettings();

      const hoje = moment().format('DD/MM/YYYY');
      const razaoSocial = this.financing.dados_complementares.empresa.nome;
      
      var outrasPlataformas = 0;
      var nestaPlataforma = 0;
      if ( pessoa.dados_complementares && pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_outras_plataformas ) {
        outrasPlataformas = pessoa.dados_complementares.perfil.investimentos_outras_plataformas;
        nestaPlataforma = pessoa.dados_complementares.perfil.investimentos_nesta_plataforma;
      }
      const montantePreFormatado = parseFloat(outrasPlataformas + nestaPlataforma).toFixed(2).replace('.', ',');
      const montante = 'R$ ' + montantePreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');;
      
      if ( publicSettings.terms ) {
        
        var contratoOfertaToReplace = '';
        if ( this.financing.possui_contrato_personalizado ) {
          contratoOfertaToReplace = this.financing.contrato_personalizado;
        }
        else if ( publicSettings.terms.contrato_padrao ) {
          contratoOfertaToReplace = publicSettings.terms.contrato_padrao;
        }

        const contratoOferta = contratoOfertaToReplace.replace('#data', hoje)
                                    .replace('#local', pessoa.endereco.cidade)
                                    .replace('#nomeInvestidor', pessoa.nome)
                                    .replace('#cpfInvestidor', pessoa.documento.numero)
                                    .replace('#razaoSocialEmissor', razaoSocial)
                                    .replace('#montante', montante);
        /**
         * A submodalidade Bacen CCB não possui contrato da oferta. O contrato é por aporte.
         * Com isso, adicionei essa condição abaixo para que funcione apenas em Bacen por enquanto.
         */
        if ( this.submodalidade == 'bacen' ) {
          let primeiroVencimentoSimulacao = this.getPrimeiroVencimentoSimulacao();
          var parcelas = "";
          if ( this.financing.fields['parcelas'] ) {
            parcelas = this.financing.fields['parcelas'].value;
          }
          var taxaJuros = "";
          if ( this.financing.fields['taxa_juros'] ) {
            taxaJuros = this.financing.fields['taxa_juros'].value;
          }
          var periodicidade = "";
          if ( this.financing.fields['periodicidade'] ) {
            periodicidade = this.financing.fields['periodicidade'].value;
          }

          var prazoFinal = 0

          if (this.financing.fields['prazo_oferta'])
            prazoFinal = this.financing.fields['prazo_oferta'].value * 30;

          // console.log('Início da Oferta', this.financing.fields['Início da Oferta'])
          // console.log('Encerramento da Oferta', this.financing.fields['Encerramento da Oferta'])
          // if ( this.financing.fields['Encerramento da Oferta'] && this.financing.fields['Início da Oferta']) {
          //   const dataInicio = moment(this.financing.fields['Início da Oferta'].value, 'DD/MM/YYYY').endOf('day');
          //   const dataFinal = moment(this.financing.fields['Encerramento da Oferta'].value, 'DD/MM/YYYY').endOf('day');
          //   prazoFinal = dataFinal.diff(dataInicio, 'days');
          // }

          let valorTarifa = this.getValorTarifa();
          const objeto = {
            tipo_pessoa: "PJ",
            valor_liberado: this.order.item.order.totalValue,
            primeiro_vencimento: primeiroVencimentoSimulacao,
            parcelas: parcelas,
            taxa_juros: taxaJuros,
            periodicidade: periodicidade,
            TAC: valorTarifa,
            prazoOperacao: prazoFinal
          }
          const that = this;
          const isBancarizadorManual = privateSettings.is_bancarizador_manual != undefined ? privateSettings.is_bancarizador_manual : false;
          const isBancarizadorEagle = privateSettings.is_bancarizador_eagle != undefined ? privateSettings.is_bancarizador_eagle : false;
          //Aqui deve realizar os cálculos manuais através do simulador local
          if ( isBancarizadorManual && isBancarizadorEagle ) {
            console.log('Iniciada execução do simulador local');
            const dados = this.getSimulacaoLocal(objeto);
            const dadosToPush = {
              payload: objeto,
              response: dados
            }
            
            that.order.ccb = {
              simulacao: {
                numero_ccb: fiduciaService.generateCCB(), //Transferir a função de geração do hash para o simulador local
                payload: objeto,
                response: dados
              }
            };

            
            await that.makeObjetoContratoCCB(pessoa);
            that.order.aceite_termos_hash = that.objetoContratoCCB.ecwl_aporte.hash_assinatura;
            that.order.investidor_ip = that.objetoContratoCCB.ecwl_aporte.investidor_ip;
            that.attachments.push({ item: {content: dadosToPush, name: 'Contrato CCB'}, url: '', tipo: 'contrato-ccb', parseRef: 'parsedContentContratoOferta' });
          }
          else {
            console.log('Simulador local não funcionou');
            fiduciaService
            .simulate(objeto)
            .then(async function (result) {
              const dados = result.resposta;
              const dadosToPush = {
                payload: objeto,
                response: dados
              }
              that.order.ccb = {
                simulacao: {
                  numero_ccb: fiduciaService.generateCCB(),
                  payload: objeto,
                  response: dados
                }
              }
              await that.makeObjetoContratoCCB(pessoa);
              that.order.aceite_termos_hash = that.objetoContratoCCB.ecwl_aporte.hash_assinatura;
              that.order.investidor_ip = that.objetoContratoCCB.ecwl_aporte.investidor_ip;
              that.attachments.push({ item: {content: dadosToPush, name: 'Contrato CCB'}, url: '', tipo: 'contrato-ccb', parseRef: 'parsedContentContratoOferta' });
            })
            .catch(function (error) {
              console.log(error);
              that.hasError = true;
              that.$toasted.show('Ocorreu um erro na geração do contrato. Por favor, entre em contato com a plataforma.', {
                icon: 'times',
                className: 'error',
              });
            });
          }
        }
        else {
          this.attachments.push({ item: {content: contratoOferta, name: 'Contrato da Oferta'}, url: '', tipo: 'editor', parseRef: 'parsedContentContratoOferta' });
        }
      }
    },
    async getTermosRisco(pessoa) {
      /**
       * Termos de Risco
       */
      const publicSettings = await settingsService.getPublicSettings();

      const hoje = moment().format('DD/MM/YYYY');
      const razaoSocial = this.financing.dados_complementares.empresa.nome;
      
      var outrasPlataformas = 0;
      var nestaPlataforma = 0;
      if ( pessoa.dados_complementares && pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_outras_plataformas ) {
        outrasPlataformas = pessoa.dados_complementares.perfil.investimentos_outras_plataformas;
        nestaPlataforma = pessoa.dados_complementares.perfil.investimentos_nesta_plataforma;
      }
      
      const montantePreFormatado = parseFloat(outrasPlataformas + nestaPlataforma).toFixed(2).replace('.', ',');
      const montante = 'R$ ' + montantePreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');;

      if ( publicSettings.terms && publicSettings.terms.termo_ciencia_risco ) {
        const termoCienciaRisco = publicSettings.terms.termo_ciencia_risco.replace('#data', hoje)
                                  .replace('#local', pessoa.endereco.cidade)
                                  .replace('#nomeInvestidor', pessoa.nome)
                                  .replace('#cpfInvestidor', pessoa.documento.numero)
                                  .replace('#razaoSocialEmissor', razaoSocial)
                                  .replace('#montante', montante);
        this.attachments.push({ 
          item: {
            content: termoCienciaRisco, 
            name: 'Termo de Ciência de Risco'
          }, 
          url: 'https://storage.googleapis.com/public-documentos-institucionais/termo-ciencia-risco.pdf', 
          tipo: 'pdf', 
          parseRef: 'parsedContentTermoCienciaRisco' 
        });
      }
    },
    async nextStep() {
      try {
        await this.uploadContrato();
      }catch(ee){
        console.log('erro upload contrato. erro: ' + ee.message)
      }
      
      this.$emit('nextStep');
    },
    async getURL(name) {
      const url = await attachmentService.urlAttachment(
        name,
        'InvestmentTerms'
      );

      return url;
    },

    pdfContratoCCBGenerated(pdfString) {
      if ( this.is_admin ) {
        this.order_admin.contrato_string = pdfString;
      }
      else {
        this.order.ccb.simulacao_contrato_string = pdfString;
        this.isLoading = false;
      }
    },

    async uploadContrato() {
      let order = this.order;

      if(!order) {
        console.log('Order objeto nao e valido upload do contrato nao realizado');
        return;
      }
      
      //console.log(order);

      if(!order.ccb.simulacao_contrato_string) {
        console.log('Order.contrato_string nao e valido upload do contrato nao realizado');
        return;
      }

      this.isLoading = true;

      var storageRef = "ContratosCCB/";
      storageRef + order.user.documento.numero;
      var fileName = order.ccb.simulacao.numero_ccb + "_" + "ContratoCCB.pdf";
      console.log(fileName);
      let response = await attachmentsService.addNewAttachment(order.ccb.simulacao_contrato_string, fileName, storageRef);
      console.log(response);
      console.log('Upload to contrato realizado')
      this.isLoading = false;
    },

    async uploadContrato_ADMIN() {
      let order = this.order_admin;

      var storageRef = "ContratosCCB/";
      storageRef + order.pessoa.documento.numero;
      var fileName = order.ccb.simulacao.numero_ccb + "_" + "ContratoCCB.pdf";
      let response = await attachmentsService.addNewAttachment(order.contrato_string, fileName, storageRef);
      this.stopControl = false;
      console.log(response);
      if ( this.zerar_contrato ) {
        this.objetoContratoCCB = {
          ecwl_aporte: {},
          simulacao: {
            parcelas: {}
          },
          ecwl_oferta: {},
          credor: {},
          emitente: {},
          contrato: {}
        }
      }
    },

    async uploadContrato_ADMIN_2() {
      // var storageRef = "ContratosCCB/";
      // storageRef + order.pessoa.documento.numero;
      // var fileName = order.ccb.simulacao.numero_ccb + "_" + "ContratoCCB.pdf";
      // let response = await attachmentsService.addNewAttachment(order.contrato_string, fileName, storageRef);
      // console.log(response);
    }
  }
};
</script>

<template>
  <div class="terms-of-use">
    <button
      class="btn btn-primary"
      type="button"
      v-if="is_admin"
      @click="regerarContrato_ADMIN"
    >
      GERAR CONTRATO
    </button>

    <button
      class="btn btn-primary"
      type="button"
      v-if="is_admin"
      @click="uploadContrato_ADMIN"
    >
      UPLOAD CONTRATO
    </button>

    <button
      class="btn btn-primary"
      type="button"
      v-if="is_admin"
      @click="generatePdfTermoUso"
    >
      TERMO DE USO PDF
    </button>

    <contrato-modelo v-if="is_admin && order_admin" :objeto="objetoContratoCCB" @pdfContratoCCBGenerated="pdfContratoCCBGenerated" />

    <div v-if="is_admin">
      <termo-uso-modelo v-for="(objetoTermoUso, index) in pdfTermosUsoLista"
                        :key="index"
                        :objeto="objetoTermoUso"
                        @uploadTermoUso_ADMIN="uploadTermoUso_ADMIN" />
    </div>

    <br /> <br />

    <h4 v-if="!is_admin">Termos de Uso e Termo de adesão e ciência de risco</h4>
    <p v-if="!is_admin">
      Clique nos termos abaixo para visualizá-los:
    </p>

    <div v-if="!is_admin" class="loading-spinner">
      <double-bounce v-if="isLoading"></double-bounce>
    </div>

    <div v-if="!is_admin" class="attachment-list" :hidden="isLoading">
      <div
        class="attachment-item"
        v-for="(attachment, index) in attachments"
        :key="index"
      >
        <h5 v-b-toggle="`accordion-${index}`">
          {{ attachment.item.name }}
          <span>
            <i class="fas fa-angle-down"></i>
          </span>
        </h5>

        <b-collapse
          :id="`accordion-${index}`"
          :visible="visible"
          accordion="my-accordion"
          role="tabpanel"
        >
          <vue-pdf-viewer
            v-if="attachment.tipo == 'pdf'"
            width="100%"
            height="500px"
            :url="attachment.url"
          ></vue-pdf-viewer>
          <div v-if="attachment.tipo !== 'pdf'" style="border: 1px solid #555; padding: 5px; overflow-y: auto; height: 400px;">
            <div v-if="financing.content" class="ql-container ql-snow" style="border: 0px !important;">
              <div class="ql-editor contrato-ccb-parent">
                <div class="contratos" v-if="attachment.tipo == 'editor'" v-html="attachment.item.content"></div>
                <div class="contratos" v-if="attachment.tipo == 'contrato-ccb'">
                  <contrato-modelo :objeto="objetoContratoCCB" @pdfContratoCCBGenerated="pdfContratoCCBGenerated" />
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <form @submit.prevent="nextStep()" v-if="!hasError && !isLoading && !is_admin">
      <p>
        Para dar continuidade ao processo, você deve aceitar os termos:
      </p>
      
      <div class="terms-accepted">
        <input type="checkbox" required v-model="termsAccepted" />
        <label for=""
          >Declaro que li e estou de acordo com todos os termos acima</label
        >
      </div>

      <div class="terms-button">
        <button
          :class="{ 'is-disabled': !termsAccepted }"
          class="button"
          type="submit"
        >
          Prosseguir
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
  .contrato-ccb-parent {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 50px;
  }
  .contratos {
    width: 100%;
    background: #fff;
  }
</style>