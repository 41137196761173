var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"formNomeCompleto"}},[_vm._v("Nome completo*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.displayName),expression:"value.displayName"}],staticClass:"form-control",attrs:{"id":"formNomeCompleto","type":"text","placeholder":"Digite seu nome completo","disabled":_vm.readonly},domProps:{"value":(_vm.value.displayName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.value, "displayName", $event.target.value)}}})])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"formEmail"}},[_vm._v("E-mail*")]),_c('div',{staticClass:"input-group mb-2"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.email),expression:"value.email"}],staticClass:"form-control fix-rounded-right",attrs:{"id":"formEmail","type":"email","placeholder":"Digite seu melhor e-mail","disabled":_vm.readonly},domProps:{"value":(_vm.value.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.value, "email", $event.target.value)}}})])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"formNascimento"}},[_vm._v("Data de nascimento*")]),_c('birth-datepicker',{attrs:{"id":"formNascimento","valueIsString":true,"high":true,"hideHeader":true,"attachment":'bottom',"delimiter":'/',"placeholder":'Digite seu nascimento. Ex: 27/12/1993',"locale":[
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
        ],"disabled":_vm.readonly},model:{value:(_vm.value.birthday),callback:function ($$v) {_vm.$set(_vm.value, "birthday", $$v)},expression:"value.birthday"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"formCPF"}},[_vm._v("CPF*")]),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"},{name:"model",rawName:"v-model",value:(_vm.value.documentNumber),expression:"value.documentNumber"}],staticClass:"form-control",attrs:{"id":"formCPF","type":"text","placeholder":"Digite seu CPF","aria-describedby":"cpfHelp","disabled":_vm.readonly},domProps:{"value":(_vm.value.documentNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.value, "documentNumber", $event.target.value)}}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v("@")])])
}]

export { render, staticRenderFns }