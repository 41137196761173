import axios from 'axios';

import { Pessoas } from '@/firebase/index';

import Vue from 'vue';
import HelperPlugin from '@/plugins/HelperPlugin';
import {
  TipoDocumento,
  TipoEndereco,
  TipoTelefone,
  TipoEmail,
  EnderecoPadrao,
  PessoaCaracteristica
} from '@/store/cwl_enums';
import firebase, { auth } from 'firebase';
Vue.use(HelperPlugin);

export default {
  _ref() {
    return firebase.firestore().collection('pessoas');
  },

  async getUsers() {
    return Pessoas.where('caracteristicas', 'array-contains-any', [
      PessoaCaracteristica.Gestor,
      PessoaCaracteristica.Curador
    ]).get();
  },

  async findById(id) {
    return (
      await this._ref()
        .doc(id)
        .get()
    ).data();
  },

  async findByEmail(email) {
    return this._ref()
          .where('email.email', '==', email)
          .get()
          .then((snapshot) => {
            return !snapshot.empty ? snapshot.docs[0].data() : null;
          });
  },

  async getPessoa(id) {
    return Pessoas.doc(id).get();
  },

  async searchByPrincipalDocument(docNumber) {
    try {
      const data = await this._ref()
        .where('documento.numero', '==', docNumber)
        .get();
      return data.size === 1 ? data.docs[0].data() : undefined;
    } catch (error) {
      console.error('search by doc error', error);
    }
  },

  async existsByDocNumber(doc) {
    const pessoa = await this.searchByPrincipalDocument(doc);
    return pessoa !== undefined;
  },

  async existsByEmail(email) {
    try {
      const data = await this._ref()
        .where('email.email', '==', email)
        .get();
      return data.size > 0;
    } catch (error) {
      console.error('search by doc error', error);
    }
  },

  async updatePessoa(id, pessoa) {
    await Pessoas.doc(id).update(pessoa);
  },

  async createNewUser(value) {
    console.debug('criando um novo usuario', value);
    const pass = Math.random()
      .toString(36)
      .slice(-10);

    const response = await auth().createUserWithEmailAndPassword(
      value.email.email,
      pass
    );

    if (response.user) {
      const uid = response.user.uid;
      const doc = this._ref().doc(uid);
      const user = {
        ...value,
        id: doc.id
      };
      doc.set(user);
      console.debug('usuario criado', uid);
      return user;
    } else {
      return undefined;
    }
  },

  async createNewPessoa(pessoa) {
    return await Pessoas.add(pessoa);
  },

  async deletePessoa(id) {
    await Pessoas.doc(id).delete();
  },

  async searchPJByCNPJ(cnpj) {
    return Pessoas.where('documento.numero', '==', cnpj).get();
  },

  async criarPessoaPJ(pessoa) {
    await this.searchPJByCNPJ(pessoa.documento.numero).then(async function(
      document
    ) {
      if (document.size > 0) {
        await Pessoas.doc(pessoa.id).update(pessoa);
      } else {
        await Pessoas.add(pessoa);
      }
    });
  },

  async searchExternalPJByCNPJ(cnpj) {
    const cnpjWithoutMask = cnpj
      .replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', '');

    const newPessoa = Vue.prototype.$CWLNewPessoaPadraoPJ(true);

    var objReturn = null;
    await axios
      .get(
        'https://api.cnpja.com.br/companies/{cnpj}?company_max_age=30'.replace(
          '{cnpj}',
          cnpjWithoutMask
        ),
        {
          headers: {
            authorization:
              'baf30a8c-5ea2-4fc7-8bb6-2658ecb2e2c8-fe29659b-f7a4-4a85-a79e-c6b2c0c27a2f'
          }
        }
      )
      .then(response => {
        const dadosEmpresa = response.data;

        const pessoa = newPessoa;

        pessoa.nome = dadosEmpresa.name;
        pessoa.dados_complementares.nome_fantasia = dadosEmpresa.alias
          ? dadosEmpresa.alias
          : '';

        /**
         * Documento
         */
        pessoa.documento.tipo = TipoDocumento.CNPJ;
        pessoa.documento.numero = cnpj;
        /**
         * Endereço
         */
        if (dadosEmpresa.address) {
          pessoa.endereco.tipo = TipoEndereco.Comercial;
          pessoa.endereco.rua = dadosEmpresa.address.street;
          pessoa.endereco.numero = dadosEmpresa.address.number;
          pessoa.endereco.complemento = dadosEmpresa.address.details;
          pessoa.endereco.bairro = dadosEmpresa.address.neighborhood;
          pessoa.endereco.cep = dadosEmpresa.address.zip;
          pessoa.endereco.cidade = dadosEmpresa.address.city;
          pessoa.endereco.estado = dadosEmpresa.address.state;
          pessoa.endereco.pais = 'BR';
        }
        /**
         * Telefone
         */
        if (dadosEmpresa.phone && dadosEmpresa.phone.length > 0) {
          pessoa.telefone.tipo = TipoTelefone.Comercial;
          pessoa.telefone.telefone = dadosEmpresa.phone;
        }
        /**
         * E-mail
         */
        if (dadosEmpresa.email && dadosEmpresa.email.length > 0) {
          pessoa.email.tipo = TipoEmail.Comercial;
          pessoa.email.email = dadosEmpresa.email;
        }

        objReturn = pessoa;
      })
      .catch(error => {
        console.log(error);
      });

    return objReturn;
  },

  async searchAddressByZipcode(cep) {
    const cepWithoutMask = cep
      .replace('.', '')
      .replace('.', '')
      .replace('/', '')
      .replace('-', '');

    const newEndereco = Object.assign({}, EnderecoPadrao);

    var objReturn = null;
    await axios
      .get(
        'https://viacep.com.br/ws/{cep}/json/'.replace('{cep}', cepWithoutMask)
      )
      .then(response => {
        const dadosEndereco = response.data;
        if (!dadosEndereco.erro) {
          const endereco = newEndereco;
          endereco.tipo = TipoEndereco.Comercial;
          endereco.rua = dadosEndereco.logradouro;
          endereco.numero = '';
          endereco.complemento = '';
          endereco.bairro = dadosEndereco.bairro;
          endereco.cep = dadosEndereco.cep;
          endereco.cidade = dadosEndereco.localidade;
          endereco.estado = dadosEndereco.uf;
          endereco.pais = 'BR';

          objReturn = endereco;
        }
      })
      .catch(error => {
        console.log(error);
      });

    return objReturn;
  }
};
