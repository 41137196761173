<script>
export default {
  name: 'credit-card-form',
  props: {
    title: {
      type: String,
    },
    error: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    console.debug('payment method initial value', this.value);
    this.amountFormatted = this.value.amount;
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
    },
    setPaymentKind(paymentKind) {
      this.value = {
        ...this.value,
        paymentKind,
      };
    },
  },
};
</script>

<template>
  <div>
    <form>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="formNumeroCartao">Nº do cartão*</label>
          <input
            id="formNumeroCartao"
            type="text"
            class="form-control"
            placeholder="0000 0000 0000 0000"
            aria-describedby="numeroCartaoHelp"
            v-mask="'#### #### #### ####'"
            v-model="value.cardNumber"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="formNomeCartao">Nome do cartão*</label>
          <input
            id="formNomeCartao"
            type="text"
            class="form-control"
            placeholder="Informe o nome do cartão"
            aria-describedby="nomeCartaoHelp"
            v-model="value.holder"
            required
          />
          <small id="nomeCartaoHelp" class="form-text text-muted"
            >O nome precisa ser igual ao que está no cartão</small
          >
        </div>
      </div>

      <div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="formValidadeCartao">Validade do cartão*</label>
            <input
              id="formValidadeCartao"
              type="text"
              class="form-control"
              placeholder="00/0000"
              aria-describedby="validadeCartaoHelp"
              v-mask="'##/####'"
              v-model="value.expirationDate"
              required
            />
          </div>

          <div class="form-group col-md-6">
            <label for="formCodigoVerificadorCartao">CVV*</label>
            <input
              id="formCodigoVerificadorCartao"
              type="text"
              class="form-control"
              placeholder="0000"
              aria-describedby="codigoVerificadorCartaoHelp"
              v-mask="'####'"
              v-model="value.securityCode"
              required
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
