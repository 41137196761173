<script>
export default {
  name: 'financing-title',
  props: {
    title: {
      type: String,
    },
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <h6
    class="card-subtitle text-muted text-center"
    style="font-size: 15px; margin-top: 5px; margin-bottom: 25px; overflow: hidden;"
  >
    <b>{{ title }}</b>
  </h6>
</template>
