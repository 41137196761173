<script>
// import Cleave from 'vue-cleave-component';

import processPaymentService from '@/services/Functions/processPaymentService';
// import cardsService from '@/services/Cards/cardsService';
import ordersService from '@/services/Orders/ordersService';
import pessoasService from '@/services/Pessoas/pessoasService';
import financingService from '@/services/Financing/financingService';
import logsService from '@/services/Logs/logsService';
import attachmentsService from '@/services/Attachments/attachmentService';
import settingsService from '@/services/Settings/settingsService';

import moment from 'moment';

import { CWLTipoPagamento } from '@/store/cwl_enums';
import { Circle2 } from 'vue-loading-spinner';
import { required } from 'vuelidate/lib/validators';
import { makePayment } from '@/services/Functions/makePayment';

export default {
  name: 'payment-investment',
  components: {
    // Cleave,
    Circle2,
  },
  data() {
    return {
      gateway_in_use: 'SAFE2PAY',
      paymentType: '',
      card: {
        number: '',
        ownerName: '',
        dueDate: '',
        cvv: '',
      },
      options: {
        creditCard: true,
        delimiter: '-',
      },
      isBackActivate: false,
      isLoading: false,
      saveCard: false,
      savedCards: [],
      addNewCard: true,
      currentCardIndex: '',
      currentCardToken: '',
      userInformations: [],
      pessoa: {},
      boletoActive: false,
      submodalidade: process.env.VUE_APP_SUBTYPE,
      CWLTipoPagamento: CWLTipoPagamento,

      metodosPagamento: {
        cartaoCredito: false,
        ted: false,
        boleto: false,
        pix: false,
      },
      response: {},

      boletoMultaVencimento: 0,
      boletoJurosVencimento: 0,
    };
  },
  validations: {
    card: {
      dueDate: {
        valid: value => {
          if (value == undefined) return false;
          var expireAt = moment(value, 'MM/YYYY').startOf('day');
          return expireAt.endOf('month').isSameOrAfter(moment().endOf('month'));
        },
      },
      number: {
        valid: required,
      },
      ownerName: {
        valid: required,
      },
      cvv: {
        valid: required,
      },
    },
  },
  computed: {
    order() {
      return this.$store.getters.getOrder;
    },
    financingId() {
      return this.$route.params.id;
    },
    loggedPessoa() {
      return this.$store.getters.getPessoa;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
  },
  async mounted() {
    // this.checkHasCards();
    await this.setupPaymentMethod();

    if (!this.loggedPessoa) {
      this.pessoa = this.$CWLNewPessoaPadrao();
    } else {
      this.getPessoa();
    }

    if (this.financingId) {
      this.get();
    }
  },
  methods: {
    nextStep() {
      this.$emit('nextStep');
    },

    async setupPaymentMethod() {
      let safe2PaySettings = await settingsService.getIntegracaoSafe2PaySettings();
      let privateSettings = await settingsService.getPrivateSettings();

      //new settings to know if it is a INTER or SAFE2PAY
      if (privateSettings.banking_api_in_use) {
        this.gateway_in_use = privateSettings.banking_api_in_use;
      }

      if (
        safe2PaySettings.credit_card.is_enabled &&
        this.order.item.order.totalValue >=
          safe2PaySettings.credit_card.min_value &&
        this.order.item.order.totalValue <=
          safe2PaySettings.credit_card.max_value
      ) {
        this.metodosPagamento.cartaoCredito = true;
        this.paymentType = CWLTipoPagamento.CartaoCredito;
      }
      if (
        safe2PaySettings.doc.is_enabled &&
        this.order.item.order.totalValue >= safe2PaySettings.doc.min_value &&
        this.order.item.order.totalValue <= safe2PaySettings.doc.max_value
      ) {
        this.metodosPagamento.boleto = true;
        this.paymentType = CWLTipoPagamento.Boleto;
        this.boletoMultaVencimento = safe2PaySettings.doc.multa_vencimento;
        this.boletoJurosVencimento = safe2PaySettings.doc.juros_vencimento;
      }
      if (
        safe2PaySettings.ted.is_enabled &&
        this.order.item.order.totalValue >= safe2PaySettings.ted.min_value &&
        this.order.item.order.totalValue <= safe2PaySettings.ted.max_value
      ) {
        this.metodosPagamento.ted = true;
        this.paymentType = CWLTipoPagamento.TED;
      }
      if (
        safe2PaySettings.pix.is_enabled &&
        this.order.item.order.totalValue >= safe2PaySettings.pix.min_value &&
        this.order.item.order.totalValue <= safe2PaySettings.pix.max_value
      ) {
        this.metodosPagamento.pix = true;
        this.paymentType = CWLTipoPagamento.PIX;
      }
    },

    async makePayment() {
      if (
        this.paymentType === CWLTipoPagamento.CartaoCredito &&
        this.$v.$invalid
      ) {
        return this.$toasted.show(
          'Informe os dados corretamente para processar o pagamento.',
          {
            icon: 'times',
            className: 'error',
            duration: 1000 * 7,
          },
        );
      }

      this.isLoading = true;

      const order = {
        email: this.pessoa.email.email,
        cliente: this.pessoa.id,
        pessoa: this.pessoa,
        nome: this.financing.name,
        financing: this.financing,
        valor: this.order.item.order.totalValue,
        valorUnitario: this.order.item.order.unitValue,
        quantidade: this.order.item.order.quantityOfQuotas,
        boletoLink: '',
        boletoCodigoBarras: '',
        aceiteTermos: true,
        ccb: this.order.ccb,
        aceite_termos_hash: this.order.aceite_termos_hash,
        investidor_ip: this.order.investidor_ip,

        card: {
          numero: this.card.number,
          nome: this.card.ownerName,
          validade: this.card.dueDate,
          codigo: this.card.cvv,
        },
      };

      //check if ccb is already in db
      //console.log('order', order)
      let existingOrder = await ordersService.getOrderByNumeroCCB(
        order.ccb.simulacao.numero_ccb,
      );
      //console.log('existingOrder', existingOrder);
      if (existingOrder && existingOrder.length > 0) {
        this.isLoading = false;
        return this.$toasted.show(
          'Ordem com mesmo CCB encontrada no banco de dados, por favor refaça a operação do começo.',
          {
            icon: 'times',
            className: 'error',
            duration: 1000 * 15,
          },
        );
      }

      //check for same orders for today
      let ordersForToday = await processPaymentService.checkSameOrders(
        order.pessoa.id,
        order.financing.id,
        order.valor,
      );
      console.log('ordersForToday', ordersForToday.data.data.total_orders);
      if (ordersForToday.data.data.total_orders > 0) {
        this.isLoading = false;
        return this.$toasted.show(
          'Parece que você já fez um aporte idêntico recentemente. Aguarde 01 dia ou informe um valor diferente.',
          {
            icon: 'times',
            className: 'error',
            duration: 1000 * 15,
          },
        );
      }

      if (this.paymentType == CWLTipoPagamento.Boleto) {
        // this.makePaymentBoleto(order);
      } else if (this.paymentType == CWLTipoPagamento.TED) {
        this.makePaymentTED(order);
      } else if (this.paymentType === CWLTipoPagamento.CartaoCredito) {
        this.makePaymentCartao(order);
      } else if (this.paymentType === CWLTipoPagamento.PIX) {
        this.makePaymentPix(order);
      }
      // else {
      //   this.makePaymentReserved(order);
      // }
    },

    async updatePessoaInvestimentoNestaPlataforma(order) {
      const pessoaFromFirebase = await pessoasService.getPessoa(
        order.pessoa.id,
      );
      var pessoaNew = pessoaFromFirebase.data();
      pessoaNew.id = pessoaFromFirebase.id;
      if (
        !pessoaNew.dados_complementares.perfil.investimentos_nesta_plataforma
      ) {
        pessoaNew.dados_complementares.perfil.investimentos_nesta_plataforma = 0;
      }
      pessoaNew.dados_complementares.perfil.investimentos_nesta_plataforma +=
        order.valor;

      await pessoasService.updatePessoa(pessoaNew.id, pessoaNew);
    },

    async makePaymentPix(order) {
      try {
        order.item = {
          code: '001',
          description: order.nome,
          value: order.valor,
          quantity: order.quantidade,
        };

        const id = await this.createNewOrder(
          CWLTipoPagamento.PIX,
          order,
          // this.response,
        ).catch(console.error);

        if (this.gateway_in_use != 'INTER') {
          console.log('calling old api bol boleto');
          this.response = await makePayment({
            financingId: order.financing.id,
            user: {
              displayName: order.pessoa.nome,
              email: order.pessoa.email?.email,
              birthday: order.pessoa.dados_complementares?.birthday,
              documentNumber: order.pessoa.documento?.numero,
            },
            value: order.valor || 0,
            paymentKind: 'pix',
            address: undefined,
            creditCard: undefined,
            bankSlip: undefined,
          });
        } else {
          console.log('calling new api bol boleto');
          this.response = await processPaymentService.processPaymentINTER(
            order,
            'PIX',
            id
          );
        }

        //this.isLoading = false;

        const that = this;
        setTimeout(function() {
          console.log('Going to success...');
          that.$router.push('/obrigado/' + id);
        }, 500);

      } catch (error) {
        console.error('make payment error', error);

        this.formDisabled = false;
        const message = error.message;
        this.$toasted.show(message, {
          icon: 'times',
          className: 'error',
          duration: 1000 * 7,
        });
        this.isLoading = false;
      }
    },

    async makePaymentCartao(order) {
      this.isLoading = true;

      order.item = {
        code: '001',
        description: order.nome,
        value: order.valorUnitario,
        quantity: order.quantidade,
      };

      const paymentData = {
        userId: order.cliente,
        financingId: order.financing.id,
        paymentKind: 'creditcard',
        items: [order.item],
        card: {
          holder: order.card.nome,
          cardNumber: order.card.numero,
          expirationDate: order.card.validade,
          securityCode: order.card.codigo,
          installmentQuantity: 1,
        },
      };

      try {
        let uploaded = await this.uploadContratoCCB(order);
        if (!uploaded) {
          this.$toasted.show(
            'Erro ao enviar o contrato. Por favor, tente novamente.',
            {
              icon: 'times',
              className: 'error',
              duration: 1000 * 7,
            },
          );
        }

        const data = await processPaymentService.processPayment(paymentData);

        await this.createNewOrder(
          CWLTipoPagamento.CartaoCredito,
          order,
          data,
        ).catch(error => console.error(error));
      } catch (error) {
        this.titleButtonContribuir = 'Contribuir';
        this.formDisabled = false;

        const message = error.message;

        this.$toasted.show(message, {
          icon: 'times',
          className: 'error',
        });
      }

      //this.isLoading = false;
    },

    async makePaymentTED(order) {
      this.isLoading = true;

      order.item = {
        code: '001',
        description: order.nome,
        value: order.valor,
        quantity: order.quantidade,
      };

      const id = await this.createNewOrder(
          CWLTipoPagamento.TED,
          order,
        // dataTransacao,
      ).catch(error => console.log(error));

      try {
        let uploaded = await this.uploadContratoCCB(order);
        if (!uploaded) {
          order.has_error_contrato_ccb = true;
        }
        if (this.gateway_in_use == 'INTER') {
          this.response = await processPaymentService.processPaymentINTER(
            order,
            'TED',
            id,
          );
        }

        // if ( uploaded ) {
        
        const that = this;

        setTimeout(function() {
          console.log('Going to success...');
          that.$router.push('/obrigado/' + id);
        }, 500);
        // }
        // else {
        //   this.titleButtonContribuir = 'Contribuir';
        //   this.formDisabled = false;
        //   this.$toasted.show('Erro ao enviar o contrato. Por favor, tente novamente.', {
        //     icon: 'times',
        //     className: 'error',
        //     duration: 1000 * 7
        //   });
        // }
      } catch (error) {
        this.isLoading = false;
        this.titleButtonContribuir = 'Contribuir';
        this.formDisabled = false;
        const message = error.message;
        this.$toasted.show(message, {
          icon: 'times',
          className: 'error',
          duration: 1000 * 7,
        });
      }

      //this.isLoading = false;
    },

    async makePaymentBoleto(order) {
      this.isLoading = true;

      const today = new Date();
      let dueDate = new Date();
      let dataEncerramentoStr = order.financing.dates.end_at;
      let dataEncerramento = moment(dataEncerramentoStr, 'YYYY/MM/DD');
      let diffDays = dataEncerramento.diff(today, 'days');
      if (diffDays >= 7) {
        dueDate.setDate(today.getDate() + 7);
      } else {
        dueDate.setDate(today.getDate() + diffDays);
      }

      let formmatedDueDate = dueDate.toLocaleString('pt-br').split(' ')[0];
      console.log(formmatedDueDate);
      formmatedDueDate = formmatedDueDate.replace(',','');
      console.log(formmatedDueDate);

      order.item = {
        code: '001',
        description: order.nome,
        value: order.valor,
        quantity: order.quantidade,
      };

      const paymentData = {
        userId: order.cliente,
        financingId: order.financing.id,
        paymentKind: 'bankslip',
        items: [order.item],
        bankSlip: {
          dueDate: formmatedDueDate,
          cancelAfterDue: false,
          isEnablePartialPayment: false,
          penaltyRate: this.boletoMultaVencimento,
          interestRate: this.boletoJurosVencimento,
          instruction:
            'Pague em qualquer agência bancária, lotérica ou internet banking.',
        },
      };

      try {
        let uploaded = await this.uploadContratoCCB(order);
        if (!uploaded) {
          order.has_error_contrato_ccb = true;
        }
        // if ( uploaded ) {
        let data = {};
        if (this.gateway_in_use != 'INTER') {
          console.log('calling old api bol boleto');
          data = await processPaymentService.processPayment(paymentData);
        } else {
          console.log('calling new api for boleto');
          data = await processPaymentService.processPaymentINTER(
            order,
            'BOLETO',
          );
          console.log(data);
        }

        const taxUrl = data.BankSlipUrl;

        const slipObject = {
          bankSlipId: data.IdTransaction,
          paymentDate: data.DueDate,
          status: 'Pendente',
        };

        processPaymentService.createSlip(slipObject).catch(console.error);

        order.boletoLink = taxUrl;
        order.boletoVencimento = formmatedDueDate;
        order.boletoCodigoBarras = data.DigitableLine;

        await this.createNewOrder(CWLTipoPagamento.Boleto, order, data).catch(
          console.error,
        );
        // }
        // else {
        //   this.titleButtonContribuir = 'Contribuir';
        //   this.formDisabled = false;
        //   this.$toasted.show('Erro ao enviar o contrato. Por favor, tente novamente.', {
        //     icon: 'times',
        //     className: 'error',
        //     duration: 1000 * 7
        //   });
        // }
      } catch (error) {
        this.isLoading = false;
        this.titleButtonContribuir = 'Contribuir';
        this.formDisabled = false;
        const message = error.message;
        this.$toasted.show(message, {
          icon: 'times',
          className: 'error',
          duration: 1000 * 7,
        });
      }

      //this.isLoading = false;
    },

    async makePaymentReserved(order) {
      this.isLoading = true;

      order.item = {
        code: '001',
        description: order.nome,
        value: order.valorUnitario,
        quantity: order.quantidade,
      };

      await this.createNewOrder(CWLTipoPagamento.Reservado, order, {});

      this.isLoading = false;
    },

    async createNewOrder(tipoPagamento, order, transaction) {
      console.debug('create new order', { tipoPagamento, order, transaction });
      // console.log(order, transaction);

      try {
        var transactionId = 0;
        var transactionToken = '';
        var pix_expiredAt = '';
        var pix_key = '';
        var pix_qrCode = '';
        if (transaction && tipoPagamento != CWLTipoPagamento.PIX) {
          transactionId = transaction.IdTransaction ? transaction.IdTransaction : '';
          transactionToken = transaction.Token ? transaction.Token : '';
        } else if (tipoPagamento == CWLTipoPagamento.PIX) {
          //TODO: adjust when has new payment gateway
          // pix_expiredAt = transaction.expireAt;
          // pix_key = '42.697.069/0001-66';
          // pix_qrCode = transaction.qrCode;
        }

        const formattedOrder = {
          pessoa: order.pessoa,
          financing_details: this.financing,
          financing_id: this.financing.id,
          aceite_termos: true,
          // doacao_privada: order.doacaoPrivada,
          item: order.item,
          status: 'Pendente',
          tipo_pagamento: tipoPagamento,
          boleto_link: order.boletoLink,
          boleto_codigo_barras: order.boletoCodigoBarras,
          boleto_vencimento: order.boletoVencimento
            ? order.boletoVencimento
            : '',
          order_date: moment().format('YYYY/MM/DD HH:mm:ss'),
          transacao_id: transactionId || '',
          transacao_token: transactionToken || '',
          pix_expiredAt: pix_expiredAt,
          pix_key: pix_key,
          pix_qrCode: pix_qrCode,
          order_origem: 'WEB',
        };

        if (order.has_error_contrato_ccb) {
          formattedOrder.has_error_contrato_ccb = true;
        }

        formattedOrder.user_agent = navigator.userAgent;

        // if (
        //   tipoPagamento == CWLTipoPagamento.Boleto ||
        //   tipoPagamento == CWLTipoPagamento.TED ||
        //   tipoPagamento == CWLTipoPagamento.PIX
        // ) {
        //   formattedOrder.status = 'Pendente';
        // } else if (tipoPagamento == CWLTipoPagamento.CartaoCredito) {
        //   formattedOrder.status = 'Pago';
        // } else {
        //   formattedOrder.status = 'Reservado';
        // }
        formattedOrder.status = 'Pendente';
        formattedOrder.item.id = this.financing.id;

        //this will be used to set order as paid on the new bankin webhook
        //if(transaction && transaction.idCorrelacao) {
        formattedOrder.idCorrelacao = order.ccb.simulacao.numero_ccb;
        formattedOrder.origem_pagamento = 'ITAU';
        //}

        /**
         * Atributo específico para a submodalidade do BACEN CCB
         */
        if (this.submodalidade == 'bacen') {
          formattedOrder.ccb = {
            numero_ccb: order.ccb.simulacao.numero_ccb,
            simulacao: order.ccb.simulacao,
            possui_contrato_download: true,
          };
          formattedOrder.aceite_termos_hash = order.aceite_termos_hash;
          formattedOrder.investidor_ip = order.investidor_ip;
        }

        const logUser = {
          user: {
            email:
              order.pessoa && order.pessoa.email
                ? order.pessoa.email.email
                : '',
          },
        };
        const logContent = formattedOrder;
        logsService.createNewLog(
          logUser,
          '',
          'Checkout - Pagar',
          '',
          logContent,
        );
        const orderCreated = await ordersService.createOrder(formattedOrder);
        var newFormattedOrder = formattedOrder;
        newFormattedOrder.id = orderCreated.id;
        const orderUpdated = await ordersService.update(orderCreated.id, newFormattedOrder);

        if (
          tipoPagamento == CWLTipoPagamento.Boleto ||
          tipoPagamento == CWLTipoPagamento.TED
        ) {
          this.financing.totais.valor_pendente =
            this.financing.totais.valor_pendente + order.valor;
        } else if (tipoPagamento == CWLTipoPagamento.Reservado) {
          this.financing.totais.valor_reservado =
            this.financing.totais.valor_reservado + order.valor;
        } else {
          this.financing.totais.valor_investido =
            this.financing.totais.valor_investido + order.valor;
        }
        this.financing.totais.valor_total =
          this.financing.totais.valor_pendente +
          this.financing.totais.valor_reservado +
          this.financing.totais.valor_investido;

        // await financingService.update(this.financing.id, this.financing);

        this.updatePessoaInvestimentoNestaPlataforma(order);

        const valorParsed = parseFloat(order.valor)
          .toFixed(2)
          .replace('.', ',');
        const valor =
          'R$ ' + valorParsed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        var objMail = {};
        // let financingPagamento = '';
        // let financingTempoRetorno = '';
        // if ( this.financing.fields['modalidade_remuneracao'] ) {
        //   financingPagamento = this.financing.fields['modalidade_remuneracao'].value;
        // }
        // if ( this.financing.fields['prazo_meses_card'] ) {
        //   financingTempoRetorno = this.financing.fields['prazo_meses_card'].value;
        // }

        // if (tipoPagamento == CWLTipoPagamento.PIX) {
        //   console.log('pay pix send email');
        //   console.log(orderCreated.id)
        //   await processPaymentService.sendMailFinalizarCompraPIX(orderCreated.id);
        // } else if (tipoPagamento == CWLTipoPagamento.Boleto) {
        //   objMail = {
        //     email: formattedOrder.pessoa.email.email,
        //     name: formattedOrder.pessoa.nome,
        //     telefone: formattedOrder.pessoa.telefone.telefone,
        //     pagamento: financingPagamento,
        //     tempo_retorno: financingTempoRetorno,
        //     campanha_nome: this.financing.name,
        //     boleto_link: formattedOrder.boleto_link,
        //     boleto_codigo_barras: formattedOrder.boleto_codigo_barras,
        //     boleto_valor: valor,
        //     boleto_vencimento: formattedOrder.boleto_vencimento
        //   };
        //   await this.$CWLsendMailFinalizarCompraBoleto(objMail);
        // } else if (tipoPagamento == CWLTipoPagamento.TED) {
        //   var bancoTED = '';
        //   var bancoNumeroTED = '';
        //   var agenciaTED = '';
        //   var contaTED = '';
        //   if ( this.financing.dados_bancarios ) {
        //     bancoTED = this.financing.dados_bancarios.banco ? this.financing.dados_bancarios.banco.name : "";
        //     bancoNumeroTED = this.financing.dados_bancarios.banco ? this.financing.dados_bancarios.banco.code : "";
        //     agenciaTED = this.financing.dados_bancarios.agencia_numero ? this.financing.dados_bancarios.agencia_numero : '';
        //     contaTED = this.financing.dados_bancarios.conta_numero ? this.financing.dados_bancarios.conta_numero : '';

        //     if ( this.financing.dados_bancarios.agencia_digito ) {
        //       agenciaTED = agenciaTED + "-" + this.financing.dados_bancarios.agencia_digito;
        //     }
        //     if ( this.financing.dados_bancarios.conta_digito ) {
        //       contaTED = contaTED + "-" + this.financing.dados_bancarios.conta_digito;
        //     }
        //   }

        //   objMail = {
        //     email: formattedOrder.pessoa.email.email,
        //     name: formattedOrder.pessoa.nome,
        //     telefone: formattedOrder.pessoa.telefone.telefone,
        //     pagamento: financingPagamento,
        //     tempo_retorno: financingTempoRetorno,
        //     valor: valor,
        //     campanha_nome: this.financing.name,
        //     banco: bancoTED,
        //     banco_numero: bancoNumeroTED,
        //     agencia: agenciaTED,
        //     conta: contaTED
        //   };
        //   await this.$CWLsendMailFinalizarCompraTED(objMail);
        // }
        // else
        if (tipoPagamento == CWLTipoPagamento.Reservado) {
          objMail = {
            email: formattedOrder.pessoa.email.email,
            name: formattedOrder.pessoa.nome,
            campanha_nome: this.financing.name,
            reserva_valor: valor,
          };
          await this.$CWLsendMailFinalizarCompraReservado(objMail);
        } else {
          console.log('add email to collection');
          await processPaymentService.addEmailToCronJob(orderCreated.id);
        }
        // } else if (tipoPagamento == CWLTipoPagamento.CartaoCredito) {
        //   objMail = {
        //     email: formattedOrder.pessoa.email.email,
        //     name: formattedOrder.pessoa.nome,
        //     campanha_nome: this.financing.name,
        //     cartao_valor: valor
        //   };
        //   this.$CWLsendMailFinalizarCompraCartao(objMail);
        // }

        //sign documento
        // try {
        //   console.log('start sign ccb', order.ccb.simulacao.numero_ccb);
        //   await processPaymentService.signCCBEmitido(
        //     orderCreated.id,
        //     order.ccb.simulacao.numero_ccb,
        //   );
        //   console.log('sign ccb com sucessp');
        // } catch (eDocumento) {
        //   console.log('Failed to sign ccb');
        //   console.log(eDocumento);
        // }
        console.log('Waiting for mail sender...');
        return orderUpdated;
      } catch (error) {
        console.log(error);
        this.$toasted.show(
          'Ocorreu um erro durante o pagamento, tente novamente.',
          {
            icon: 'times',
            className: 'error',
          },
        );

        this.titleButtonContribuir = 'Contribuir';
        this.formDisabled = false;
      }
    },
    async uploadContratoCCB(order) {
      var storageRef = 'ContratosCCB/';
      storageRef + order.pessoa.documento.numero;
      var fileName = order.ccb.simulacao.numero_ccb + '_' + 'ContratoCCB.pdf';
      let response = await attachmentsService.addNewAttachment(
        order.ccb.simulacao_contrato_string,
        fileName,
        storageRef,
      );

      return response.state == 'success' && response.totalBytes > 3000000;
    },
    selectActiveCard(index) {
      this.currentCardIndex = index;
      const currentCard = this.savedCards[index];
      this.currentCardToken = this.savedCards[index].card.Token;

      this.card.number = `*********** ${currentCard.savedInformations.card}`;
      this.card.ownerName = currentCard.savedInformations.holder;
      this.card.dueDate = '**/**';
      this.card.cvv = '***';
    },
    enableToAddNewCard() {
      this.addNewCard = true;

      this.card = {
        number: '00000000000',
        ownerName: 'Seu Nome',
        dueDate: '00/0000',
        cvv: '000',
      };
    },
    async getPessoa() {
      const { pessoa_id } = this.loggedPessoa;
      const pessoa = await pessoasService.getPessoa(pessoa_id);

      this.pessoa = pessoa.data();
      this.pessoa.id = pessoa.id;
    },

    async get() {
      this.financing = await financingService.findById(this.financingId);

      this.isLoading = false;

      await this.calculosDaCampanha(this.financing);
    },
    async calculosDaCampanha(financ) {
      return this.$CWLCampanhaCalculos(financ);
    },
  },
};
</script>

<template>
  <div class="payment">
    <div class="payment-informations">
      <h4>Informações de Pagamento</h4>
      <p>
        Selecione a forma de pagamento, e preencha com seus dados
      </p>

      <div class="payment-type-buttons">
        <button
          v-if="metodosPagamento.cartaoCredito"
          class="button"
          :class="{
            'is-activated': paymentType === CWLTipoPagamento.CartaoCredito,
          }"
          style="margin-right: 2px;"
          @click="paymentType = CWLTipoPagamento.CartaoCredito"
        >
          <i class="fas fa-check"></i>
          Cartão de Crédito
        </button>

        <button
          v-if="metodosPagamento.boleto"
          class="button"
          :class="{ 'is-activated': paymentType === CWLTipoPagamento.Boleto }"
          style="margin-right: 2px;"
          @click="paymentType = CWLTipoPagamento.Boleto"
        >
          <i class="fas fa-check"></i>
          Boleto Bancário
        </button>

        <button
          v-if="metodosPagamento.ted"
          class="button"
          :class="{ 'is-activated': paymentType === CWLTipoPagamento.TED }"
          @click="paymentType = CWLTipoPagamento.TED"
        >
          <i class="fas fa-check"></i>
          TED
        </button>

        <button
          v-if="metodosPagamento.pix"
          class="button"
          :class="{ 'is-activated': paymentType === CWLTipoPagamento.PIX }"
          @click="paymentType = CWLTipoPagamento.PIX"
        >
          <i class="fas fa-check"></i>
          PIX
        </button>
      </div>

      <div
        class="card-informations"
        v-if="paymentType === CWLTipoPagamento.CartaoCredito"
      >
        <div
          style="margin-right: 5px;"
          class="card"
          :class="{ 'is-turn': isBackActivate }"
        >
          <div class="front">
            <span class="card-number">
              {{ card.number }}
            </span>

            <div class="card-column">
              <span class="owner-name">
                <small>Nome como está no Cartão</small>
                <span>
                  {{ card.ownerName }}
                </span>
              </span>

              <span class="due-date">
                <small>Validade</small>
                {{ card.dueDate }}
              </span>
            </div>
          </div>

          <div class="back">
            <span class="back-line"></span>

            <span class="cvv">
              <small>CVV</small>
              {{ card.cvv }}
            </span>
          </div>
        </div>

        <div class="card-form">
          <div class="column" @click="isBackActivate = false">
            <label>N° do Cartão</label>
            <input
              type="text"
              class="input"
              name="card"
              placeholder="0000-0000-0000-0000"
              v-model="card.number"
              v-mask="'####-####-####-####'"
            />
          </div>

          <div class="column">
            <label>Nome como no Cartão</label>
            <input
              @click="isBackActivate = false"
              type="text"
              class="input"
              placeholder="Nome do Cartão"
              v-model="card.ownerName"
            />
          </div>

          <div class="columns">
            <div class="column">
              <label>Validade</label>
              <input
                @click="isBackActivate = false"
                v-mask="'##/####'"
                type="text"
                class="input"
                placeholder="12/2026"
                v-model="card.dueDate"
              />
            </div>

            <div class="column">
              <label>CVV</label>
              <input
                @click="isBackActivate = true"
                type="text"
                class="input"
                placeholder="000"
                v-mask="'###'"
                v-model="card.cvv"
              />
            </div>
          </div>

          <div class="column">
            <div class="form-group">
              <div class="error" v-if="$v.$invalid">
                Informe todos os campos.
              </div>
              <div
                class="error"
                v-if="card.dueDate != '' && !$v.card.dueDate.valid"
              >
                Informe um mês e ano válido.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="investment-informations">
      <h5>
        <i class="fas fa-wallet"></i>
        Resumo da Contratação
      </h5>

      <div class="column">
        <label>
          Nome da Contratação
        </label>
        {{ order.item.name }}
      </div>

      <div class="column total">
        <label>Total:</label>
        {{ order.item.order.totalValue | currency }}
      </div>

      <button
        class="button finish"
        @click="makePayment()"
        :class="{ 'in-process': isLoading }"
        v-if="!isLoading"
      >
        <i class="fas fa-lock" v-if="!isLoading"></i>
        <circle-2 v-if="isLoading" :disabled="isLoading"></circle-2>
        Contratar
      </button>
      <button
        class="button finish"
        :class="{ 'in-process': isLoading }"
        v-if="isLoading"
      >
        <i class="fas fa-lock" v-if="!isLoading"></i>
        <circle-2 v-if="isLoading" :disabled="isLoading"></circle-2>
        Processando pagamento...
    </button>
    </div>
  </div>
</template>
