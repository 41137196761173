import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const vuexKeyByEnvironment = key => {
  const suffix = process.env.VUE_APP_VUEX_KEY_SUFFIX || '';
  return key + suffix;
};

const getDefaultState = () => ({
  paymentCheckout: {
    financingId: null,
  },
  user: {},
  claim: {},
  pessoa: {},
  oferta: {},
  order: {
    user: {},
    item: {},
    payment: {},
  },
});

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key =>
          Cookies.get(vuexKeyByEnvironment(key), {
            domain: process.env.VUE_APP_DOMAIN,
            path: '/',
          }),
        setItem: (key, value) =>
          Cookies.set(vuexKeyByEnvironment(key), value, {
            expires: 1,
            secure: false,
            domain: process.env.VUE_APP_DOMAIN,
            path: '/',
          }),
        removeItem: key =>
          Cookies.remove(vuexKeyByEnvironment(key), {
            domain: process.env.VUE_APP_DOMAIN,
            path: '/',
          }),
      },
    }),
  ],
  mutations: {
    /**
     * Set paymant checkout info
     * @param {*} state
     * @param {*} value { financingId }
     */
    SET_PAYMENT_CHECKOUT: (state, value) => {
      state.paymentCheckout = value;
    },
    SET_ITEM: (state, item) => {
      state.order.item = item;
    },
    SET_USER: (state, user) => {
      state.order.user = user;
      state.user = user;
    },
    SET_PESSOA: (state, pessoa) => {
      state.pessoa = pessoa;
    },
    SET_PAYMENT: (state, payment) => {
      state.order.payment = payment;
    },
    SET_OFERTA_ID_TEMP: (state, oferta) => {
      if (oferta.length == 0) {
        state.oferta = {};
      } else {
        state.oferta.oferta_id = oferta;
      }
    },
  },
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => state.user,
    getOrder: state => state.order,
    getPessoa: state => state.pessoa,
    getOferta: state => state.oferta,
    userClaim: (state) => state.claim || { role: 'Usuário' },
  },
});
