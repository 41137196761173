/**
 *
 * @param {*} value
 * @param {*} options { prefix: boolean }
 * @returns
 */
 export const formatCurrency = (value, options = { prefix: true }) => {
  const formatted = parseFloat(value || 0)
    .toFixed(2)
    .toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return options.prefix ? `R$ ${formatted}` : formatted;
};

export default {
  formatCurrency,
};
