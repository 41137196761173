var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-body"},[_vm._m(0),(_vm.paymentKind === 'creditcard')?_c('div',[_c('div',{staticClass:"card-text"},[_vm._v(" O pagamento foi confirmado e a sua contribuição já foi registrada. ")])]):_vm._e(),(_vm.paymentKind === 'bankslip')?_c('div',[_c('div',{staticClass:"card-text"},[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(" Pague o boleto agora ou até a data do vencimento para confirmar sua contribuição. ")]),(_vm.barcode)?_c('div',{staticStyle:{"margin-bottom":"40px"}},[_c('hr',{staticStyle:{"border-top-color":"#6c757d","opacity":"0.1"}}),_c('h6',{staticClass:"card-subtitle text-muted text-center",staticStyle:{"margin-bottom":"10px","margin-top":"20px"}},[_vm._v(" "+_vm._s(_vm.barcode)+" ")]),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Copiado!',
              trigger: 'manual',
              show: _vm.copiedTooltip,
            }),expression:"{\n              content: 'Copiado!',\n              trigger: 'manual',\n              show: copiedTooltip,\n            }"},{name:"clipboard",rawName:"v-clipboard",value:(_vm.barcode),expression:"barcode"}],staticClass:"card-text text-muted text-center",staticStyle:{"cursor":"pointer","color":"#00bf9a !important","text-decoration":"underline"},on:{"success":_vm.showCopiedTooltip}},[_c('b',[_vm._v("Copiar código de barras")])])]):_vm._e(),(_vm.barcodeUrl)?_c('div',{staticClass:"row"},[_c('hr',{staticStyle:{"border-top-color":"#6c757d","opacity":"0.1"}}),_c('div',{staticClass:"col-md-4"}),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"alert alert-success",staticStyle:{"cursor":"pointer","background-color":"#17a2b8"},attrs:{"role":"alert"},on:{"click":_vm.openBarcode}},[_c('h5',{staticClass:"card-subtitle text-muted text-center",staticStyle:{"color":"#fff !important","margin-top":"0px"}},[_vm._v(" Visualizar Boleto ")])])]),_c('div',{staticClass:"col-md-4"})]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"card-text",staticStyle:{"margin-top":"20px"}},[_vm._v(" Valor: "),_c('b',{staticStyle:{"color":"#00bf9a"}},[_vm._v(_vm._s(_vm.value))])]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('h5',{staticClass:"card-subtitle text-muted text-center",staticStyle:{"color":"#fff !important","margin-top":"0px"}},[_vm._v(" OBRIGADO POR CONTRIBUIR COM ESTA CAUSA! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"form-text text-muted text-right",staticStyle:{"margin-top":"40px"}},[_c('i',[_vm._v("Também enviamos estas informações para o seu e-mail.")])])
}]

export { render, staticRenderFns }