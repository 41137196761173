import { Logs } from '@/firebase/index';

export default {
  createNewLog(
    request,
    class_name,
    class_method_name,
    request_method,
    content
  ) {
    if (request.user && request.user.email) {
      console.debug('create new log');
      Logs.add({
        user: request.user.email,
        class_name,
        class_method_name,
        request_method,
        platform: 'portal',
        content,
        date: new Date()
      });
      console.debug('log added');
    }
  },

  getAllLogs() {
    return Logs.orderBy('date', 'desc').get();
  }
};
