import firebase from 'firebase';

export default {
  financingRef() {
    return firebase.firestore().collection('financing');
  },

  async findAll() {
    try {
      const snapshot = await this.financingRef().get();
      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map(value => ({
        id: value.id,
        ...value.data()
      }));
    } catch (error) {
      console.error('financing fnd all error', error);
    }
  },

  async findById(id) {
    try {
      const financing = (
        await this.financingRef()
          .doc(id)
          .get()
      ).data();
      if (!financing.id) {
        financing.id = id;
      }
      return financing;
    } catch (error) {
      console.error('financing servic find by id error', error);
      throw Error('Não foi possível consultar os dados da campanha.');
    }
  },

  async update(id, financing) {
    try {
      await this.financingRef()
        .doc(id)
        .update(financing);
    } catch (error) {
      console.error('financing update error', error);
    }
  }
};
