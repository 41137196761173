import { functions } from 'firebase/app';
/**
 * {
 *  user: { id: string; username: string; displayName: string; documentNumber: string; email: string; birthday: string; };
 *  value: string;
 *  paymentKind: 'bankslip' | 'creditcard';
 *  address: { postalCode: string; publicPlace: string; number: string; complement: string; district: string; state: string; city: string;};
 * }
 * @param {*} data
 * @returns
 */
export const makePayment = async data => {
  const fn = functions().httpsCallable('makePayment');
  const result = await fn(data);
  if (result.data.error) {
    throw new Error(result.data.error.message);
  }
  return result.data;
};

export default makePayment;
