<script>
import Vue from 'vue';

import pessoasService from '@/services/Pessoas/pessoasService';
import { DoubleBounce } from 'vue-loading-spinner';
import vSelect from 'vue-select';

Vue.component('v-select', vSelect);

export default {
  name: 'address-form',
  props: {
    error: {
      type: String,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Endereço Residencial',
    },
    initialValue: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      address: { ...this.initialValue },
      states: [],
      cities: [],
    };
  },
  computed: {},
  components: {
    DoubleBounce,
  },
  methods: {
    handleChange() {
      this.$emit('change', this.address);
    },
    async searchCEP(event) {
      try {
        const matched = (event.target.value || '').match(/\d+/g);
        const value = matched ? matched.join('') : '';
        if (value.length >= 7) {
          this.loading = true;
          const address = await pessoasService.searchAddressByZipcode(value);
          if (address) {
            const {
              rua,
              numero,
              complemento,
              bairro,
              cidade,
              estado,
              pais,
            } = address;

            this.address = {
              ...this.address,
              publicPlace: rua || this.address.publicPlace,
              number: numero || this.address.number,
              complement: complemento || this.address.complement,
              district: bairro || this.address.district,
              city: cidade || this.address.city,
              state: estado || this.address.state,
              country: pais || this.address.country,
            };

            this.handleChange();
          }
        }
      } catch (error) {
        console.error('error on seach address', error);
      }

      this.loading = false;
    },
  },
  async mounted() {},
};
</script>

<template>
  <div style="margin-top: 25px">
    <div class="row col-md-12">
      <h6 slot="header" class="title" style="margin-bottom: 25px;">
        {{ title }}
      </h6>
    </div>

    <double-bounce v-if="loading"></double-bounce>
    <form @submit.prevent="handleChange()" @change="handleChange()">
      <div style="color: red" v-if="error">{{ error }}</div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="CEP">CEP*</label>
          <input
            id="CEP"
            type="text"
            class="form-control"
            placeholder="Informe o CEP do seu endereço"
            aria-describedby="cepHelp"
            v-mask="['##.###-###']"
            v-on:change="searchCEP"
            v-model="address.cep"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="CEP">Rua*</label>
          <input
            id="CEP"
            type="text"
            class="form-control"
            placeholder="Informe a rua do endereço"
            aria-describedby="ruaHelp"
            v-model="address.publicPlace"
          />
        </div>
        <div class="form-group col-md-12">
          <label for="NUMERO">Número*</label>
          <input
            id="NUMERO"
            type="text"
            class="form-control"
            placeholder="Informe o número do endereço"
            aria-describedby="ruaHelp"
            v-model="address.number"
          />
        </div>
        <div class="form-group col-md-12">
          <label for="COMPLEMENTO">Complemento</label>
          <input
            id="COMPLEMENTO"
            type="text"
            class="form-control"
            placeholder="Informe o complemento do endereço"
            aria-describedby="complementoHelp"
            v-model="address.complement"
          />
        </div>
        <div class="form-group col-md-12">
          <label for="BAIRRO">Bairro*</label>
          <input
            id="BAIRRO"
            type="text"
            class="form-control"
            placeholder="Informe o bairro do endereço"
            aria-describedby="bairroHelp"
            v-model="address.district"
          />
        </div>
        <div class="form-group col-md-12">
          <label for="CIDADE">Cidade*</label>
          <input
            id="CIDADE"
            type="text"
            class="form-control"
            placeholder="Informe a cidade do endereço"
            aria-describedby="cidadeHelp"
            v-model="address.city"
          />
        </div>
        <div class="form-group col-md-12">
          <label for="ESTADO">Estado*</label>
          <input
            id="ESTADO"
            type="text"
            class="form-control"
            placeholder="Informe o estado do endereço"
            aria-describedby="estadoHelp"
            v-model="address.state"
          />
        </div>
      </div>
    </form>
  </div>
</template>
