import { Orders } from '@/firebase/index';

export default {
  async createOrder(order) {
    const value = await Orders.add(order);
    console.log('order added', order);
    return value;
  },

  async getOrderByNumeroCCB(numeroCCB) {

    return Orders.where('ccb.numero_ccb', '==', numeroCCB).get();
  },

  async getPaidAndWithoutTermoUsoPdfOrders() {
    return Orders.where('status_w_has_termo_uso', '==', 'Pago_Nao').limit(10).get();
  },

  async getPaidAndWithoutTermoUsoPdfOrdersSemLimite() {
    return Orders.where('status_w_has_termo_uso', '==', 'Pago_Nao').get();
  },

  async getPaidOrders() {
    return Orders.where('status', '==', 'Pago').get();
  },

  getOrder(id) {
    return Orders.doc(id).get();
  },

  async update(id, order) {
    await Orders.doc(id).update(order);
    return id;
  },
};
