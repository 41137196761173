<script>
export default {
  name: 'contribution-public-field',
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    onChange: {
      type: Function,
    },
  },
  data() {
    return {
      value: this.checked || false,
    };
  },
};
</script>
<template>
  <div class="form-row">
    <div class="form-group form-check" style="padding-left: 25px;">
      <input
        type="checkbox"
        class="form-check-input"
        id="formDoacaoPubica"
        v-model="value"
        v-on:input="$emit('change', !checked)"
      />
      <label class="form-check-label" for="formDoacaoPubica"
        >Quero que minha doação não seja pública</label
      >
    </div>
  </div>
</template>

<style scoped></style>
